import {
  // PreloadedState,
  combineReducers,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";
import patientsReducer from "./slices/patients/patientSlice";
import ctsReducer from "../redux/slices/clinical-trial-sites/ctsSlice";
import clinicalTrialReducer from "./slices/clinical-trials/clinicalTrialSlice";
import MDsReducer from "./slices/mds/mdsSlice";
import usersReducer, { UserState } from "./slices/users/userSlice";
import { useDispatch } from "react-redux";

const rootReducer = combineReducers({
  patients: patientsReducer,
  clinicalTrialSites: ctsReducer,
  clinicalTrials: clinicalTrialReducer,
  MDs: MDsReducer,
  userState: usersReducer as Reducer<UserState>,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export const useAppDispatch = () => useDispatch<AppDispatch>();
