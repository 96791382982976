import "src/components/form-styles.css";
import "./CTForm.css";
import "src/components/CT/Card/CTCard.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Switch from "@mui/material/Switch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import CloseIcon from "@mui/icons-material/Close";
import { ClinicalTrialSite } from "src/types/ClinicalTrialSite";
import { ClinicalTrial, TextBlock } from "src/types/ClinicalTrial";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  createClinicalTrial,
  updateClinicalTrial,
} from "src/redux/slices/clinical-trials/clinicalTrialActions";
import { getAllCTS } from "src/redux/slices/clinical-trial-sites/ctsActions";
import FilledBtn from "src/components/Buttons/FilledBtn";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import CustomSelect from "src/components/Inputs/IconSelect";
import TextInput from "src/components/Inputs/TextInput";
import OutlinedBtn from "src/components/Buttons/OutlinedBtn";
import Modal from "src/components/Modal/Modal";
import Spinner from "src/components/Spinner/Spinner";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

const selectItems = [
  { icon: "visibility", value: "Eye" },
  { icon: "neurology", value: "Brain" },
  { icon: "ecg_heart", value: "Heart" },
  { icon: "nephrology", value: "Kidneys" },
  { icon: "pulmonology", value: "Lungs" },
  { icon: "bloodtype", value: "Blood" },
  { icon: "genetics", value: "Genetics" },
  { icon: "stethoscope", value: "Stethoscope" },
  { icon: "gastroenterology", value: "Intestine" },
];

const CTForm = () => {
  // Utils
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const auth = getAuth();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  // Functionality
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);
  const [token, setToken] = useState("");
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("loggedUser") ?? "")?.role ??
      loggedUser?.role
  );
  const [clinicalTrial, setClinicalTrial] = useState<ClinicalTrial>({
    name: "",
    icon: "",
    ongoing: false,
    text_blocks: [{ title: "", paragraph: "" }],
  });
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedCTS, setSelectedCTS] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const cts = useAppSelector((s) => s.clinicalTrialSites.cts);
  const [errors, setErrors] = useState<{
    name: string | undefined;
    textblocks: { index: string; desc: string | null }[];
    cts: string | undefined;
  }>({ name: undefined, textblocks: [], cts: undefined });
  const [isTrimComplete, setIsTrimComplete] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState<boolean>(false);
  const ct = useAppSelector((s) => s.clinicalTrials.clinicalTrials);

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  function trimCT() {
    const trimmedTB = clinicalTrial.text_blocks.map((tb) => {
      return { title: tb.title?.trim(), paragraph: tb.paragraph.trim() };
    });
    setClinicalTrial({
      ...clinicalTrial,
      name: clinicalTrial.name.trim(),
      text_blocks: trimmedTB,
    });
    setIsTrimComplete(true);
  }

  useEffect(() => {
    if (isTrimComplete) {
      setIsTrimComplete(false);
      setFirstSubmit(true);
      let tbValidation = null;
      const textblocksError: { desc: string; index: string }[] = [];
      clinicalTrial.text_blocks.forEach((tb, index) => {
        tbValidation = validateTextblock(tb);
        if (tbValidation)
          textblocksError.push({ desc: tbValidation, index: String(index) });
      });

      const errorName = validateName(clinicalTrial.name.toLowerCase());

      const ctsError = validateSelectedCTS();

      if (!errorName && !textblocksError.length && !ctsError) {
        if (state && state.type === "edit") handleEdit();
        else handleCreate();
      } else {
        setErrors({
          name: errorName,
          textblocks: textblocksError,
          cts: ctsError,
        });
        setLoading(false);
      }
    }
  }, [isTrimComplete]);

  // Modal
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleShowModal = () => {
    setIsOpen((currentIsOpen) => {
      return !currentIsOpen;
    });
  };

  useEffect(() => {
    getToken().then((res) => setToken(res ?? ""));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (token.length && role.length) {
      getCTS().finally(() => setLoading(false));
    }
  }, [token]);

  const getCTS = async () => {
    try {
      const result = await dispatch(getAllCTS());

      if (result.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsOpen(false);
        },
      });

      handleShowModal();
    }
  };

  useEffect(() => {
    if (state && state?.type === "edit" && state?.trial) {
      const trial = state.trial;
      setClinicalTrial({ ...trial, icon: makeCapitalLetter(trial.icon) });
      const iconToSet = selectItems
        .filter((i) => i.icon === trial.icon)
        .at(0)?.value;
      setSelectedIcon(iconToSet ? iconToSet : "visibility"); // Default value
    }
  }, []);

  useEffect(() => {
    const selectedCTSNames: string[] = [];
    if (state) {
      cts.map((cts: ClinicalTrialSite) => {
        if (state.trial.clinicalTrialSites.includes(cts.id))
          selectedCTSNames.push(cts.name);
      });
      setSelectedCTS(selectedCTSNames);
    }
  }, [cts]);

  const addTextBlock = () => {
    const newTextBlocks = [
      ...clinicalTrial.text_blocks,
      { title: "", paragraph: "" },
    ];
    setClinicalTrial({ ...clinicalTrial, text_blocks: newTextBlocks });
  };

  const validateName = (name: string) => {
    if (name.length > 50)
      return "Please enter a name with 50 characters or less.";
    if (!state || name !== state.trial.name.toLowerCase()) {
      if (ct.some((item: ClinicalTrial) => item.name.toLowerCase() === name))
        return "The name you entered is already in use, please enter a new one.";
    }
    return undefined;
  };

  const validateTextblock = (tb: TextBlock) => {
    if (tb.title && tb.title.length > 100)
      return "Please enter a title with 100 characters or less.";
    if (tb.paragraph.length > 500)
      return "Please enter a paragraph with 500 characters or less.";
    return null;
  };

  const validateSelectedCTS = () => {
    if (!selectedCTS.length)
      return "At least one clinical trial site must be selected.";
  };

  const handleChangeTitle = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputs = clinicalTrial.text_blocks.map((textblock, i) => {
      if (i === index) {
        return { title: event.target.value, paragraph: textblock.paragraph };
      }
      return textblock;
    });
    setClinicalTrial({ ...clinicalTrial, text_blocks: newInputs });
  };

  const handleChangeParagraph = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputs = clinicalTrial.text_blocks.map((textblock, i) => {
      if (i === index) {
        return { title: textblock.title, paragraph: event.target.value };
      }
      return textblock;
    });

    setClinicalTrial({ ...clinicalTrial, text_blocks: newInputs });
  };

  useEffect(() => {
    if (firstSubmit) {
      let tbValidation = null;
      const textblocksError: { desc: string; index: string }[] = [];
      clinicalTrial.text_blocks.forEach((tb, index) => {
        tbValidation = validateTextblock(tb);
        if (tbValidation)
          textblocksError.push({ desc: tbValidation, index: String(index) });
      });
      setErrors({ ...errors, textblocks: textblocksError });
    }
  }, [clinicalTrial.text_blocks]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    trimCT();
  };

  const handleCreate = () => {
    setLoading(true);
    const iconToSet = selectItems
      .filter((i) => i.value === selectedIcon)
      .at(0)?.icon;

    const selectedCTSIds: string[] = [];

    cts.map((cts: ClinicalTrialSite) => {
      if (selectedCTS.includes(cts.name))
        selectedCTSIds.push(cts.id ? cts.id : "");
    });

    dispatch(
      createClinicalTrial({
        clinicalTrialToCreate: {
          ...clinicalTrial,
          icon: iconToSet,
          name: makeCapitalLetter(clinicalTrial.name),
          clinicalTrialSites: selectedCTSIds,
        },
        token: token,
        role: role,
      })
    )
      .then((response: any) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The clinical trial was created successfully.",
            function: () => {
              navigate("/clinical-trials");
              setIsOpen(false);
            },
          });

          handleShowModal();
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
        }
      })
      .catch(() => {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      });
  };

  const handleEdit = () => {
    setLoading(true);
    const iconToSet = selectItems
      .filter((i) => i.value === selectedIcon)
      .at(0)?.icon;

    const selectedCTSIds: string[] = [];

    cts.map((cts: ClinicalTrialSite) => {
      if (selectedCTS.includes(cts.name))
        selectedCTSIds.push(cts.id ? cts.id : "");
    });

    dispatch(
      updateClinicalTrial({
        idToUpdate: clinicalTrial.id,
        clinicalTrialUpdated: {
          ...clinicalTrial,
          name: makeCapitalLetter(clinicalTrial.name),
          icon: iconToSet,
          clinicalTrialSites: selectedCTSIds,
        },
        token: token,
        role: role,
      })
    )
      .then((response: any) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The clinical trial was updated successfully.",
            function: () => {
              navigate("/clinical-trials");
              setIsOpen(false);
            },
          });

          handleShowModal();
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
        }
      })
      .catch(() => {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      });
  };

  const handleDeleteTextblock = (index: number) => {
    const arrayUpdated = clinicalTrial.text_blocks.filter((tb, tbIndex) => {
      return tbIndex !== index;
    });
    setClinicalTrial({ ...clinicalTrial, text_blocks: arrayUpdated });
  };

  const handleDisabledBtn = !(
    clinicalTrial.name.length > 0 &&
    clinicalTrial.text_blocks.every((i) => i.paragraph.length > 0) &&
    selectedIcon !== "" &&
    selectedCTS.length > 0
  );

  const deleteCTSFunction = (value: string) => {
    setSelectedCTS(selectedCTS.filter((cts) => cts !== value));
  };

  const handleCtsChange = (e: SelectChangeEvent<string[]>) => {
    const value = e.target.value;
    const selectedCTS = typeof value === "string" ? value.split(",") : value;
    setSelectedCTS(selectedCTS);
  };

  const renderedTextBlocks = clinicalTrial.text_blocks.map(
    (textblock, index) => {
      const hasError = errors.textblocks.find(
        (tb) => Number(tb.index) === index
      );
      return (
        <div key={index}>
          <Accordion
            defaultExpanded
            sx={{
              borderRadius: "4px",
              backgroundColor: "#F2F2F2",
              width: "99%",
              position: "unset",
            }}
            key={index}
          >
            <AccordionSummary
              key={index}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                padding: "0 8px",
                margin: 0,
                "& .Mui-expanded": {
                  maxHeight: "40px",
                  "&.MuiAccordionSummary-content": {
                    margin: 0,
                  },
                },
                "&.MuiButtonBase-root.MuiAccordionSummary-root": {
                  minHeight: "45px",
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <h3 className="ct-form-block-title">Block {index + 1}</h3>
                {clinicalTrial.text_blocks.length > 1 && (
                  <IconButton
                    onClick={() => {
                      handleDeleteTextblock(index);
                    }}
                    sx={{ padding: 0 }}
                    key={index}
                  >
                    <DeleteOutlineIcon
                      sx={{ color: "#6F7374", fontWeight: 100 }}
                    />
                  </IconButton>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                marginBottom: "8px",
              }}
              key={index}
            >
              <TextInput
                name="title"
                label={"Title"}
                placeholder={"Title"}
                value={textblock.title ? textblock.title : ""}
                changeFunction={(e) => {
                  handleChangeTitle(index, e);
                }}
                required={false}
                labelSize="14px"
              />
              <TextInput
                name="paragraph"
                label={"Paragraph"}
                placeholder={"Clinical trial information"}
                value={textblock.paragraph}
                changeFunction={(e) => {
                  handleChangeParagraph(index, e);
                }}
                labelSize="14px"
                multiline={true}
              />
            </AccordionDetails>
          </Accordion>
          {hasError ? (
            <span
              style={{
                color: "red",
                fontSize: "11px",
                marginTop: "8px",
                display: isDesktop ? "block" : "auto",
              }}
            >
              {hasError.desc}
            </span>
          ) : undefined}
        </div>
      );
    }
  );

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100vh",
            boxSizing: "border-box",
            zIndex: 1001,
            width: "100%",
            background: "rgb(183, 190, 208,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : undefined}
      {window.location.href.includes("/edit/") && !state ? (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">Clinical trial not found</h3>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="w100">
          <div
            className="d-flex justify-space-between align-center w100"
            style={{ height: "35px", marginBottom: "8px" }}
          >
            <div className="d-flex justify-space-between align-center">
              <ArrowBackBtn onClick={() => navigate("/clinical-trials")} />
              <h2
                className="font-title"
                style={{
                  lineHeight: "30px",
                  fontSize: "24px",
                  marginLeft: "8px",
                }}
              >
                Clinical trial
              </h2>
            </div>
            <FilledBtn
              disabled={handleDisabledBtn}
              width="77px"
              fontSize="14px"
              text={"Save"}
            />
          </div>
          <Slide in={true} direction="up" mountOnEnter unmountOnExit>
            <div
              className="form-container ct-form-container"
              style={{
                maxHeight: isDesktop ? "75vh" : "65vh",
              }}
            >
              <div className="toggle-ongoing">
                <h4
                  className="trial-ongoing"
                  style={{
                    backgroundColor: clinicalTrial.ongoing
                      ? "#58BD9F"
                      : "#F2F2F2",
                    color: clinicalTrial.ongoing ? "black" : "#6F7374",
                  }}
                >
                  ongoing
                </h4>
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#58BD9F",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#58BD9F",
                    },
                  }}
                  checked={clinicalTrial.ongoing}
                  onChange={(_e, checked) =>
                    setClinicalTrial({ ...clinicalTrial, ongoing: checked })
                  }
                />
              </div>
              <div>
                <div className="ct-form-head">
                  <div style={{ width: isDesktop ? "50%" : "60%" }}>
                    <div>
                      <TextInput
                        name="trial-name"
                        label={"Name"}
                        placeholder={"Name"}
                        value={clinicalTrial.name}
                        changeFunction={(e) => {
                          if (firstSubmit)
                            setErrors({
                              ...errors,
                              name: validateName(e.target.value),
                            });
                          setClinicalTrial({
                            ...clinicalTrial,
                            name: e.target.value,
                          });
                        }}
                        height="39px"
                        labelSize="14px"
                      />
                    </div>
                  </div>
                  <CustomSelect
                    label="Icon"
                    handleChange={(e) => setSelectedIcon(e.target.value)}
                    items={selectItems}
                    selectedValue={selectedIcon}
                    name={"Icon"}
                    height="39px"
                    width={isDesktop ? "50%" : "35%"}
                    fontSize="14px"
                    errorText=" "
                    hasError
                  />
                </div>
                <div className="w100">
                  {errors.name ? (
                    <span
                      style={{
                        width: "50%",
                        color: "red",
                        fontSize: "11px",
                        display: isDesktop ? "block" : "inline",
                        marginTop: "4px",
                      }}
                    >
                      {errors.name}
                    </span>
                  ) : undefined}
                </div>
              </div>
              <hr />
              <h3 className="ct-form-information-title">
                Clinical trial sites*
              </h3>
              <FormControl>
                <Select
                  id="ct-per-cts"
                  multiple
                  value={selectedCTS}
                  onChange={handleCtsChange}
                  input={<OutlinedInput id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value: string) => (
                        <Chip
                          onDelete={() => deleteCTSFunction(value)}
                          sx={{ paddingRight: "12px" }}
                          deleteIcon={
                            <CloseIcon
                              onMouseDown={(event) => event.stopPropagation()}
                              style={{
                                border: "1.5px solid",
                                borderRadius: "100%",
                                fontSize: "14px",
                                color: "black",
                                margin: 0,
                              }}
                            />
                          }
                          key={value}
                          label={value}
                        />
                      ))}
                    </Box>
                  )}
                  sx={{
                    fontFamily: "DM Sans, sans-serif",
                    background: "white",
                    borderRadius: "8px",
                    fontSize: "14px",
                    color: "rgba(5, 10, 12, 1)",
                    textAlign: "left",
                    letterSpacing: "0.5px",
                    "& fieldset": {
                      borderWidth: "1px",
                      borderColor: "rgba(29, 97, 152, 1) !important",
                      borderRadius: "8px",
                    },
                  }}
                >
                  {cts.map((cts: ClinicalTrialSite, index: number) => (
                    <MenuItem
                      key={index}
                      value={cts.name}
                      sx={{
                        fontFamily: "DM Sans, sans-serif",
                      }}
                    >
                      {cts.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.cts ? (
                <span
                  style={{
                    color: "red",
                    fontSize: "9px",
                    display: "block",
                    marginTop: "4px",
                  }}
                >
                  {errors.cts}
                </span>
              ) : undefined}
              <hr />
              <div>
                <h3 className="ct-form-information-title">
                  Information for patient app
                </h3>
                <p className="ct-form-subtitle">
                  You can add as many text blocks as needed. If you only need a
                  paragraph without title, just leave the title input empty.
                </p>
              </div>
              <section
                className="ct-form-text-blocks-container"
                style={{
                  overflowY: isDesktop ? "auto" : "scroll",
                  maxHeight: isDesktop ? "40vh" : "30vh",
                }}
              >
                {renderedTextBlocks}
                <div className="ct-form-new-block-button">
                  <OutlinedBtn
                    text={"Add new text block"}
                    clickFunction={addTextBlock}
                    disabled={false}
                    width="50%"
                  />
                </div>
              </section>
            </div>
          </Slide>
        </form>
      )}
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "error" ? "Try again" : "Accept"}
        handleAction={modal.function}
        handleClose={
          modal.action === "success"
            ? modal.function
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};

export default CTForm;
