import "./Modal.css";
import { Dialog, IconButton } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FilledBtn from "src/components/Buttons/FilledBtn";
import OutlinedBtn from "src/components/Buttons/OutlinedBtn";

interface ModalProps {
  open: boolean;
  action?: string;
  title: string;
  content: string;
  btnActionText?: string;
  btnErrorText?: string;
  handleClose: (e?: any) => void;
  handleAction: (e?: any) => void;
}

const Modal: React.FC<ModalProps> = ({
  open = false,
  action = "error",
  title,
  content,
  btnActionText = "Try again",
  btnErrorText = "Cancel",
  handleClose,
  handleAction,
}: ModalProps) => {
  const iconDim = { height: "40px", width: "40px" };
  const icon =
    action === "error" ? (
      <ErrorIcon sx={{ ...iconDim, color: "#F7474B" }} />
    ) : action === "warning" ? (
      <WarningRoundedIcon sx={{ ...iconDim, color: "#ffd966" }} />
    ) : (
      <CheckCircleRoundedIcon
        color="success"
        sx={{ ...iconDim, color: "#58bd9f" }}
      />
    );

  const buttons =
    action === "success" ? (
      <div className="buttonsPanel">
        <FilledBtn
          disabled={false}
          onClick={handleAction}
          text={btnActionText}
          width="92px"
        />
      </div>
    ) : (
      <div className="buttonsPanel">
        {action !== "error" && (
          <OutlinedBtn
            disabled={false}
            clickFunction={handleClose}
            text={btnErrorText}
            width="92px"
            color="#F7474B"
            borderColor="#F7474B"
          />
        )}

        <FilledBtn
          backgroundColor={action === "error" ? "#F7474B" : ""}
          disabled={false}
          onClick={handleAction}
          text={btnActionText}
          width="92px"
        />
      </div>
    );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "330px",
          height: "244px",
          borderRadius: "4px",
          border: "1px",
          gap: "16px",
          textAlign: "center",
          letterSpacing: "0.5px",
          fontFamily: "Karla, sans-serif",
          background: "white",
          margin: 0,
        },
      }}
    >
      <div className="modal-container">
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: "12px", right: "12px" }}
        >
          <HighlightOffIcon
            color="disabled"
            sx={{
              height: "24px",
              width: "24px",
            }}
          />
        </IconButton>
        {icon}
        <div className="modal-text">
          <h2 className="modal-title">{title}</h2>
          <p className="modal-content">{content}</p>
        </div>
        {buttons}
      </div>
    </Dialog>
  );
};

export default Modal;
