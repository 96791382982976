import { createSlice } from "@reduxjs/toolkit";
import {
  createCTS,
  getAllCTS,
  updateCTS,
  deleteCTS,
  getCTSById,
  getCTSByEmail,
} from "./ctsActions";
import { Statuses } from "../interfaces";
import { ClinicalTrialSite } from "../../../types/ClinicalTrialSite";
export interface CTSState {
  cts: ClinicalTrialSite[];
  loggedCTS: ClinicalTrialSite | null;
  loading: boolean;
  error: string | any;
  status: Statuses;
}

const initialState: CTSState = {
  cts: [],
  loggedCTS: null,
  loading: false,
  error: null,
  status: "idle",
};

export const ctsSlice = createSlice({
  name: "cts",
  initialState,
  reducers: {
    setLogout(state: CTSState) {
      state.status = "idle";
      state.error = null;
      state.cts = [];
      state.loggedCTS = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllCTS.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getAllCTS.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.error) state.cts = action.payload;
      })
      .addCase(getAllCTS.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createCTS.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          const index = state.cts.findIndex(
            (cts) => cts.id === action.payload.id
          );
          index === -1 &&
            state.cts.push(action.payload as unknown as ClinicalTrialSite);
        }
      })
      .addCase(createCTS.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCTS.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCTS.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.cts.findIndex(
          (cts) => cts.id === action.payload.id
        );
        if (index !== -1) {
          state.cts[index] = action.payload;
        } else {
          state.cts.push(action.payload);
        }
      })
      .addCase(updateCTS.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCTS.rejected, (state, action) => {
        state.status = "failed";
        state.error = null;
      })
      .addCase(deleteCTS.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          state.cts = state.cts.filter(
            (cts: ClinicalTrialSite) => cts.id !== action.meta.arg.idToDelete
          );
        }
      })
      .addCase(getCTSById.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error && action.payload.id) {
          const index = state.cts.findIndex(
            (cts) => cts.id === action.payload.id
          );
          if (index === -1) {
            state.cts.push(action.payload);
          }
        }
      })
      .addCase(getCTSByEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loggedCTS = action.payload;
      });
  },
});

type State = { ctsState: CTSState };
export const selectAllCTS = (state: State) => state.ctsState.cts;
export const selectCTSStatus = (state: State) => state.ctsState.status;
export const selectCTSError = (state: State) => state.ctsState.error;

export default ctsSlice.reducer;
