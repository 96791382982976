import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  getUserById,
  getUserByEmail,
} from "./userActions";
import { Statuses } from "../interfaces";
import User from "../../../types/User";

export interface UserState {
  users: User[];
  loggedUser: User | null;
  loading: boolean;
  error: string | any;
  status: Statuses;
}

const initialState: UserState = {
  users: [],
  loggedUser: JSON.parse(localStorage.getItem("loggedUser") || null),
  loading: false,
  error: null,
  status: "idle",
};

export const userSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setLoggedUser(state : UserState, action : PayloadAction<User>) {
      state.loggedUser = action.payload;
    },
    clearLoggedUser(state : UserState) {
      state.loggedUser = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state : UserState) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state : UserState, action) => {
        state.status = "succeeded";
        if (!action.payload.error) state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state : UserState, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createUser.fulfilled, (state : UserState, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          const index = state.users.findIndex(
            (user) => user.id === action.payload.id
          );
          index === -1 && state.users.push(action.payload as unknown as User);
        }
      })
      .addCase(createUser.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = "null";
      })
      .addCase(updateUser.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          state.users = state.users.filter(
            (user: User) => user.id !== action.meta.arg.idToDelete
          );
        }
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        if (index !== -1) {
          state.users[index] = action.payload as unknown as User;
        } else state.users.push(action.payload as unknown as User);
      })
      .addCase(getUserById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getUserByEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.users.findIndex(
          (user) => user.email === action.payload.email
        );
        if (index !== -1) {
          state.users[index] = action.payload as unknown as User;
        } else state.users.push(action.payload as unknown as User);
      })
      .addCase(getUserByEmail.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUserByEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

type State = { userState: UserState };
export const selectAllUsers = (state: State) => state.userState.users;
export const selectCurrentUser = (state: State) => state.userState.loggedUser;
export const selectUserStatus = (state: State) => state.userState.status;
export const selectUsersError = (state: State) => state.userState.error;

export default userSlice.reducer;
