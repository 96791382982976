import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAppSelector } from "src/redux/hooks";

const PrivateRoute = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { currentUser } = getAuth();
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);
  const location = useLocation();

  return currentUser && allowedRoles.includes(loggedUser?.role ?? "") ? (
    <Outlet />
  ) : currentUser && loggedUser ? (
    <Navigate to="/forbidden" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
