import "src/pages/page-styles.css";
import "./UserForm.css";
import "src/components/Inputs/CustomInput/CustomInput";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { DocumentData } from "@google-cloud/firestore";
import {
  MenuItem,
  SelectChangeEvent,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import User from "src/types/User";
import { useAppDispatch } from "src/redux/hooks";
import { createUser, updateUser } from "src/redux/slices/users/userActions";
import { RootState } from "src/redux/store";
import TextInput from "src/components/Inputs/TextInput";
import Modal from "src/components/Modal/Modal";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import FilledBtn from "src/components/Buttons/FilledBtn";
import SelectInput from "src/components/Inputs/SelectInput";
import Spinner from "src/components/Spinner/Spinner";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

const UserForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const users: User[] = useSelector(
    (state: RootState) => state.userState.users
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState<{
    action: string;
    title: string;
    content: string;
  }>({ action: "", title: "", content: "" });
  const [errors, setErrors] = useState<{ email?: string }>({});

  const [loading, setLoading] = useState(false);

  const [firstSubmit, setFirstSubmit] = useState<boolean>(false);

  const handleShowModal = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value ?? "" });
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    if (firstSubmit) {
      setErrors({ email: validateEmail(email) });
    }
    setFormData({ ...formData, email: event.target.value ? email : "" });
  };

  const [formData, setFormData] = useState<User>({
    email: "",
    role: "admin",
  });

  const handleDisabledBtn = !(
    formData.email.length > 0 &&
    (formData.role === "admin" || formData.role === "cts")
  );

  const validateEmail = (email: string) => {
    const emailRegex = /^[\w.-]+@amydis\.com$/;
    if (!emailRegex.test(email))
      return "Please enter a valid @amydis.com email.";

    if (!state || (state && state.user?.email.toLowerCase() !== email))
      if (
        users.some(
          (item: User) => email.toLowerCase() === item.email.toLowerCase()
        )
      )
        return "The email is already by another user.";
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFirstSubmit(true);

    const emailError = validateEmail(formData.email);

    if (!emailError) {
      if (state && state.type === "edit") {
        handleEdit();
      } else handleCreate();
    } else setErrors({ email: emailError });
  };

  const handleEdit = () => {
    setLoading(true);
    dispatch(
      updateUser({
        idToUpdate: state.user.id,
        userUpdated: formData,
        token: state.token,
        role: state.role,
      })
    )
      .then((response: DocumentData) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The user was updated successfully.",
          });

          handleShowModal();
        } else {
          setLoading(false);
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
          });

          handleShowModal();
        }
      })
      .catch((_error: any) => {
        setLoading(false);
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
        });

        handleShowModal();
      });
  };

  const handleCreate = () => {
    setLoading(true);
    dispatch(
      createUser({
        userToCreate: formData,
        token: state.token,
        role: state.role,
      })
    )
      .then((response: DocumentData) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The user was created successfully.",
          });

          handleShowModal();
        } else {
          setLoading(false);
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
          });

          handleShowModal();
        }
      })
      .catch((_error: any) => {
        setLoading(false);
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
        });

        handleShowModal();
      });
  };

  useEffect(() => {
    if (state && state?.type === "edit" && state?.user) {
      setFormData(state.user);
    }
  }, []);

  const modalFunction =
    modal.action === "error"
      ? () => {
          setLoading(false);
          handleShowModal();
        }
      : () => {
          handleShowModal();
          navigate("/users");
        };

  return (
    <>
      {loading ? (
        <div>
          <div
            style={{
              position: "absolute",
              top: "0",
              left: 0,
              height: "100vh",
              boxSizing: "border-box",
              zIndex: 1001,
              width: "100%",
              background: "#00000075",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1002",
            }}
          >
            <Spinner color="#B7BED0" size={60} />
          </div>
        </div>
      ) : undefined}
      <div
        className="page-container"
        style={{
          paddingLeft: isDesktop ? "25%" : "",
          paddingRight: isDesktop ? "15%" : "",
          boxSizing: "border-box",
        }}
      >
        <form onSubmit={handleSubmit} className="w100">
          <div className="d-flex justify-space-between align-center w100">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ArrowBackBtn onClick={() => navigate("/users")} />
              <h2 className="font-title" style={{ marginLeft: "8px" }}>
                User
              </h2>
            </div>
            <FilledBtn width="77px" text="Save" disabled={handleDisabledBtn} />
          </div>
          <Slide in={true} direction="up" mountOnEnter unmountOnExit>
            <div
              className={"user-form w100 d-flex flex-column"}
              style={{
                overflowY: isDesktop ? "auto" : "scroll",
                gap: "12px",
              }}
            >
              <div className="w100">
                <TextInput
                  name={"email"}
                  label={"Email"}
                  placeholder={"Email"}
                  value={formData.email || ""}
                  changeFunction={handleChangeEmail}
                  labelSize="12px"
                  margin="0 0 .2em 0"
                />
                {errors.email ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "9px",
                      textAlign: "left",
                      display: "block",
                      margin: "4px",
                    }}
                  >
                    {errors.email}
                  </span>
                ) : undefined}
              </div>
              <SelectInput
                name={"role"}
                label={"Role"}
                placeholder={"Role"}
                value={formData.role || ""}
                items={["admin", "cts"].map((type, i) => {
                  return (
                    <MenuItem value={type} key={i}>
                      {makeCapitalLetter(type)}
                    </MenuItem>
                  );
                })}
                changeFunction={handleChange}
                errorText=""
              />
            </div>
          </Slide>
        </form>

        <Modal
          open={isOpen}
          action={modal.action}
          title={modal.title}
          content={modal.content}
          btnActionText={modal.action === "error" ? "Try again" : "Accept"}
          handleAction={modalFunction}
          handleClose={modalFunction}
        />
      </div>
    </>
  );
};

export default UserForm;
