import { createContext, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "src/firebaseConfig";
import { useAppDispatch } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { userSlice } from "src/redux/slices/users/userSlice";
import { patientsSlice } from "src/redux/slices/patients/patientSlice";
import { ctsSlice } from "src/redux/slices/clinical-trial-sites/ctsSlice";
import { clinicalTrialSlice } from "src/redux/slices/clinical-trials/clinicalTrialSlice";
import { MDsSlice } from "src/redux/slices/mds/mdsSlice";

const AuthContext = createContext<any>(null);

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const AuthProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.userState.loggedUser
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (_user) => {
      const userFromStorage = JSON.parse(
        localStorage.getItem("loggedUser") || "null"
      );
      if (userFromStorage) {
        dispatch(userSlice.actions.setLoggedUser(userFromStorage));
      }

      setLoading(false);
    });
    return () => unsubscribe();
  }, [dispatch]);

  const logout = () => {
    signOut(auth).then(() => {
      dispatch(userSlice.actions.clearLoggedUser());
      dispatch(patientsSlice.actions.setLogout());
      dispatch(ctsSlice.actions.setLogout());
      dispatch(clinicalTrialSlice.actions.setLogout());
      dispatch(MDsSlice.actions.setLogout());
      localStorage.removeItem("loggedUser");
    });
  };

  return (
    <AuthContext.Provider value={{ currentUser, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
