import "./NotFound.css";
import { useNavigate } from "react-router-dom";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { useAppSelector } from "src/redux/hooks";

const NotFound = () => {
  const navigate = useNavigate();
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);

  function handleReturn() {
    loggedUser?.role === "admin"
      ? navigate("/home")
      : loggedUser?.role === "cts"
      ? navigate("/patients")
      : navigate("/users");
  }

  return (
    <div style={{ height: "100vh" }}>
      <div className="not-found-container">
        <ElectricalServicesIcon
          color="disabled"
          sx={{ width: "120px", height: "120px" }}
        />
        <div className="not-found-text">
          <h1 className="not-found-title">Oops!</h1>
          <h2 className="not-found-subtitle">404 page not found</h2>
          <h3 className="not-found-content">
            The page you're lookng for might have been moved, renamed, or is
            temporary unavailable.
          </h3>
        </div>
        <button className="not-found-button" onClick={handleReturn}>
          Back to home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
