import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ClinicalTrialSite } from "../../../types/ClinicalTrialSite";
const { REACT_APP_BACKEND_BASE_URL } = process.env;

export const getAllCTS: any = createAsyncThunk(
  "clinical-trial-sites/getAllCTS",
  async () => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial-site/getAllCTS`;
      const ctsResponse = await axios.get<any>(url);
      return ctsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const createCTS: any = createAsyncThunk(
  "clinical-trial-site/createCTS",
  async ({
    ctsToCreate,
    token,
    role,
  }: {
    ctsToCreate: ClinicalTrialSite;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial-site/createCTS`;
      const ctsResponse = await axios.post<any>(url, ctsToCreate, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return ctsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const updateCTS: any = createAsyncThunk(
  "clinical-trial-sites/updateCTS",
  async (
    {
      idToUpdate,
      ctsUpdated,
      token,
      role,
    }: {
      idToUpdate: string;
      ctsUpdated: ClinicalTrialSite;
      token: string;
      role: string;
    },
    thunkAPI
  ) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial-site/updateCTS/${idToUpdate}`;
      const ctsResponse = await axios.put<any>(url, ctsUpdated, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return ctsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const deleteCTS: any = createAsyncThunk(
  "clinical-trial-sites/deleteCTS",
  async ({
    idToDelete,
    token,
    role,
  }: {
    idToDelete: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial-site/deleteCTS/${idToDelete}`;
      const ctsResponse = await axios.delete<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return ctsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const getCTSById: any = createAsyncThunk(
  "clinical-trial-sites/getCTSById",
  async ({
    idToGet,
    token,
    role,
  }: {
    idToGet: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial-site/getCTSById/${idToGet}`;
      const ctsResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return ctsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const getCTSByEmail: any = createAsyncThunk(
  "clinical-trial-sites/getCTSByEmail",
  async ({
    email,
    token,
    role,
  }: {
    email: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial-site/getCTSByEmail/${email}`;
      const ctsResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return ctsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);
