import "./CTDetails.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { ArrowDropDownIcon } from "@mui/x-date-pickers/icons";
import { ClinicalTrial } from "src/types/ClinicalTrial";
import { ClinicalTrialSite } from "src/types/ClinicalTrialSite";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { getCTSById } from "src/redux/slices/clinical-trial-sites/ctsActions";
import { deleteClinicalTrial } from "src/redux/slices/clinical-trials/clinicalTrialActions";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import Modal from "src/components/Modal/Modal";
import Spinner from "src/components/Spinner/Spinner";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

const CTDetail = () => {
  // Utils
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  // Data
  const { state: data } = useLocation();
  const trial = data?.trial as ClinicalTrial;
  const cts = useAppSelector((s) => s.clinicalTrialSites.cts);
  // Func
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCTS, setSelectedCTS] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });

  const handleDelete = async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
    try {
      const result = await dispatch(
        deleteClinicalTrial({
          idToDelete: trial.id,
          token: data.token,
          role: data.role,
        })
      );
      if (!result.payload.error) {
        navigate("/clinical-trials");
      } else {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  const handleEdit = () => {
    navigate(`/clinical-trials/edit/${trial.id}`, {
      replace: false,
      state: {
        trial: data.trial,
        type: "edit",
        token: data.token,
        role: data.role,
      },
    });
  };

  useEffect(() => {
    getCTSNames();
  }, []);

  const getCTSNames = async () => {
    (async () => {
      setLoading(true);
      const ctsNames = await Promise.all(
        trial.clinicalTrialSites
          ? trial.clinicalTrialSites.map(async (t) => {
              const i = cts.find((cts: ClinicalTrialSite) => t === cts.id);
              if (i !== undefined) {
                setLoading(false);
                return i.name;
              } else {
                try {
                  const response = await dispatch(
                    getCTSById({
                      idToGet: t,
                      token: data.token,
                      role: data.role,
                    })
                  );
                  if (!response.payload.error) return response.payload?.name;
                  else {
                    setModal({
                      action: "error",
                      title: "Uh oh!",
                      content: "An unexpected error occurred.",
                      function: () => {
                        setIsOpen(false);
                      },
                    });
                    setIsOpen(true);
                  }
                } catch (error) {
                  setModal({
                    action: "error",
                    title: "Uh oh!",
                    content: "An unexpected error occurred.",
                    function: () => {
                      setIsOpen(false);
                    },
                  });
                  setIsOpen(true);
                }
              }
            })
          : []
      );

      let result: string[] = [];
      ctsNames.forEach((name) => {
        if (name !== undefined) result.push(name);
      });

      setSelectedCTS(result);
    })();
    setLoading(false);
  };

  const renderedTextBlocks = trial?.text_blocks.map((textblock, index) => {
    return (
      <Accordion
        defaultExpanded
        sx={{
          borderRadius: "4px",
          backgroundColor: "#F2F2F2",
          width: "99%",
          position: "unset",
        }}
        key={index}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: "0 8px",
            margin: 0,
            "& .Mui-expanded": {
              maxHeight: "40px",
              "&.MuiAccordionSummary-content": {
                margin: 0,
              },
            },
            "&.MuiButtonBase-root.MuiAccordionSummary-root": {
              minHeight: "50px",
            },
          }}
        >
          <h3 className="ct-form-block-title">Block {index + 1}</h3>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "8px",
          }}
        >
          {textblock.title ? (
            <h3 className="ct-details-trial-name">{textblock.title}</h3>
          ) : undefined}
          <p className="ct-details-paragraph">{textblock.paragraph}</p>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100vh",
            boxSizing: "border-box",
            zIndex: 1001,
            width: "100%",
            background: "rgb(183, 190, 208,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : undefined}
      <div
        className="d-flex justify-space-between align-center w100"
        style={{
          paddingBottom: isDesktop ? "4px" : "",
          borderBottom: isDesktop ? "1px solid #B7BED0" : "",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowBackBtn onClick={() => navigate("/clinical-trials")} />
          <h2 className="font-title" style={{ marginLeft: "8px" }}>
            Clinical trial
          </h2>
        </div>
        {!data ? undefined : (
          <div>
            <IconButton
              size={isDesktop ? "large" : "small"}
              style={{ color: "#F7474B", padding: isDesktop ? "4px" : "" }}
              onClick={(e) => {
                e.stopPropagation();
                setModal({
                  action: "warning",
                  title: "Are you sure?",
                  content:
                    "You are about to delete the clinical trial. This action cannot be undone.",
                  function: () => handleDelete,
                });
                setIsOpen(true);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
            <IconButton
              size={isDesktop ? "large" : "small"}
              onClick={handleEdit}
              style={{ color: "#1D6198", padding: isDesktop ? "4px" : "" }}
            >
              <EditIcon />
            </IconButton>
          </div>
        )}
      </div>

      {!data ? (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">Clinical trial not found</h3>
        </div>
      ) : (
        <Zoom
          in={true}
          style={{ transitionDelay: "100ms" }}
          mountOnEnter
          unmountOnExit
        >
          <div
            className="ct-form-container-details w100 d-flex flex-column"
            style={{
              alignItems: "start",
              marginTop: isDesktop ? "8px" : "",
              height: isDesktop ? "75vh" : "70vh",
            }}
          >
            <section
              className="ct-form-header w100"
              style={{ marginBottom: "4px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="material-symbols-outlined ct-details-icon">
                  {trial.icon}
                </span>
                <h3 className="ct-details-trial-name">
                  {makeCapitalLetter(trial.name)}
                </h3>
              </div>
              <h4
                className="trial-ongoing"
                style={{
                  backgroundColor: trial.ongoing ? "#58BD9F" : "#F2F2F2",
                  color: trial.ongoing ? "black" : "#6F7374",
                }}
              >
                ongoing
              </h4>
            </section>
            <hr style={{ margin: "8px 0", width: "100%" }} />
            <h3
              className="ct-form-information-title"
              style={{ margin: 0, fontWeight: 500 }}
            >
              Clinical trial sites
            </h3>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                borderRadius: "8px",
                border: "1px solid #1D6198",
                minHeight: "80px",
                width: "100%",
              }}
            >
              {selectedCTS.map((value: string, index) => (
                <Chip
                  key={index}
                  label={value}
                  style={{
                    margin: "9px",
                    marginLeft: index % 2 !== 0 ? "0" : "9px",
                    marginRight: index % 2 === 0 ? "9px" : "0",
                  }}
                />
              ))}
            </Box>

            <hr style={{ margin: "8px 0", width: "100%" }} />

            <section
              className="ct-details-text-blocks-container w100"
              style={{
                overflowY: isDesktop ? "auto" : "scroll",
              }}
            >
              <h3
                className="ct-form-information-title"
                style={{ margin: 0, fontWeight: 500 }}
              >
                Information for patient app
              </h3>
              {renderedTextBlocks}
            </section>
          </div>
        </Zoom>
      )}
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        handleClose={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        handleAction={
          modal.action === "error"
            ? (e) => {
                e.stopPropagation();
                setIsOpen(false);
              }
            : (e) => handleDelete(e)
        }
        btnActionText={modal.action === "warning" ? "Delete" : "Try again"}
      />
    </div>
  );
};

export default CTDetail;
