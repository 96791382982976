import { CircularProgress } from "@mui/material";

interface SpinnerProps {
  color?: string;
  size?: string | number;
}

const Spinner: React.FC<SpinnerProps> = ({
  color = "rgba(29, 97, 152, 1)",
  size = 40,
}: SpinnerProps) => {
  return (
    <div>
      <CircularProgress
        size={size}
        style={{
          color: color,
        }}
      />
    </div>
  );
};

export default Spinner;
