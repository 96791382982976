import "src/components/card-styles.css";
import "./PatientCard.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Patient from "src/types/Patient";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

interface PatientProps {
  patient: Patient;
  clinicalTrial: string | undefined;
}

const PatientCard: React.FC<PatientProps> = ({ patient, clinicalTrial }) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClick = () => {
    navigate(`details/${patient.id}`, {
      state: { ...patient, ctName: clinicalTrial },
    });
  };

  return (
    <div className="card" onClick={handleClick}>
      <div
        className="patient-header"
        style={{
          padding: isDesktop ? "8px 24px" : 0,
        }}
      >
        <span className="patient-name">
          {patient.name} {patient.lastName}
        </span>

        <span className="patient-trial">
          {clinicalTrial ? makeCapitalLetter(clinicalTrial) : clinicalTrial}
        </span>
      </div>
    </div>
  );
};

export default PatientCard;
