import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Login } from "src/pages/Login/Login";
import Patients from "src/pages/Patients/Patients";
import OngoingCTs from "src/pages/OngoingCTs/OngoingCTs";
import BottomNav from "src/components/Navigation/BottomNav/BottomNav";
import TopBar from "src/components/TopBar/TopBar";
import "./App.css";
import { CTSManager } from "src/pages/CTSManager/CTSManager";
import NotFound from "src/pages/NotFound/NotFound";
import CTSForm from "src/components/CTS/Form/CTSForm";
import { CTSDetails } from "src/components/CTS/Details/CTSDetails";
import PatientDetails from "src/components/Patients/Details/PatientDetails";
import CTForm from "src/components/CT/Form/CTForm";
import CTDetails from "src/components/CT/Details/CTDetails";
import MDSManager from "src/pages/MDsManager/MDSManager";
import { MDDetails } from "src/components/MD/Details/MDDetails";
import MDForm from "src/components/MD/Form/MDForm.tsx";
import Home from "src/pages/Home/Home";
import { useMediaQuery, useTheme } from "@mui/material";
import SideNav from "src/components/Navigation/SideNav/SideNav";
import UserManager from "src/pages/UserManager/UserManager";
import UserForm from "src/components/Users/Form/UserForm";
import PrivateRoute from "src/components/PrivateRoute/PrivateRoute";
import Forbidden from "src/pages/Forbidden/Forbidden";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

const AppContent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const currentUser = useSelector(
    (state: RootState) => state.userState.loggedUser
  );

  const routesWithBars = [
    "^/home$",
    "^/patients(/(details/[a-zA-Z0-9]+)?)?$",
    "^/clinical-trials(/(create|details/[a-zA-Z0-9]+|edit/[a-zA-Z0-9]+)?)?$",
    "^/cts-manager(/(create|details/[a-zA-Z0-9]+|edit/[a-zA-Z0-9]+)?)?$",
    "^/mds-manager(/(create|details/[a-zA-Z0-9]+|edit/[a-zA-Z0-9]+)?)?$",
  ];

  const regex = new RegExp(routesWithBars.join("|"));

  const showBottomNav = currentUser && regex.test(location.pathname);

  return (
    <div className="App">
      {currentUser && !location.pathname.includes("/login") && <TopBar />}
      <div className={`content ${currentUser ? "with-top-bar" : ""}`}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forbidden" element={<Forbidden />} />
          <Route
            path="/home"
            element={<PrivateRoute allowedRoles={["admin"]} />}
          >
            <Route index element={<Home />} />
          </Route>
          <Route
            path="/users/*"
            element={<PrivateRoute allowedRoles={["master"]} />}
          >
            <Route index element={<UserManager />} />
            <Route path="create" element={<UserForm />} />
            <Route path="edit/:id" element={<UserForm />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path="/patients/*"
            element={<PrivateRoute allowedRoles={["cts"]} />}
          >
            <Route index element={<Patients />} />
            <Route path="details/:id" element={<PatientDetails />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path="/clinical-trials/*"
            element={<PrivateRoute allowedRoles={["admin"]} />}
          >
            <Route index element={<OngoingCTs />} />
            <Route path="create" element={<CTForm />} />
            <Route path="edit/:id" element={<CTForm />} />
            <Route path="details/:id" element={<CTDetails />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path="/cts-manager/*"
            element={<PrivateRoute allowedRoles={["admin"]} />}
          >
            <Route index element={<CTSManager />} />
            <Route path="create" element={<CTSForm />} />
            <Route path="edit/:id" element={<CTSForm />} />
            <Route path="details/:id" element={<CTSDetails />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path="/mds-manager/*"
            element={<PrivateRoute allowedRoles={["admin", "cts"]} />}
          >
            <Route index element={<MDSManager />} />
            <Route path="create" element={<MDForm />} />
            <Route path="edit/:id" element={<MDForm />} />
            <Route path="details/:id" element={<MDDetails />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      {showBottomNav && (!isDesktop ? <BottomNav /> : <SideNav />)}
    </div>
  );
};

export const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};
