import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { MD } from "../../../types/MD";
const { REACT_APP_BACKEND_BASE_URL } = process.env;

interface AddNewMDParams {
  newMD: MD;
}

export const fetchMDs: any = createAsyncThunk(
  "mds/fetchMDs",
  async ({ token, role }: { token: string; role: string }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/mds/getAllMDs`;
      const MDsResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      MDsResponse.data.sort((a: MD, b: MD) => {
        if (a.ts_create === b.ts_create) return 0;
        else return a.ts_create ? -1 : 1;
      });

      return MDsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unexpected error occurred." };  
    }
  }
);

export const addNewMD: any = createAsyncThunk(
  "mds/createMD",
  async (params: AddNewMDParams) => {
    try {
      const { newMD } = params;
      const response = await axios.post(
        `${REACT_APP_BACKEND_BASE_URL}/mds/createMD`,
        newMD
      );

      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const updateMD: any = createAsyncThunk(
  "mds/updateMD",
  async ({
    idToUpdate,
    mdUpdated,
    token,
    role,
  }: {
    idToUpdate: string;
    mdUpdated: MD;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/mds/updateMD/${idToUpdate}`;
      const mdResponse = await axios.put<any>(url, mdUpdated, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return mdResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const deleteMD: any = createAsyncThunk(
  "mds/deleteMD",
  async ({
    idToDelete,
    token,
    role,
  }: {
    idToDelete: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/mds/deleteMD/${idToDelete}`;
      const response = await axios.delete<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const fetchMdById: any = createAsyncThunk(
  "mds/:id",
  async ({
    idToGet,
    token,
    role,
  }: {
    idToGet: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/mds/${idToGet}`;
      const response = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);
