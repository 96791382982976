import { IconButton } from "@mui/material"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

interface BtnArrowBackProps {
    onClick: () => void;
    width?: string;
    height?: string;

}


const ArrowBackBtn : React.FC<BtnArrowBackProps> = ({
    onClick,
    width="24px",
    height="24px"
}) => {
    return <IconButton onClick={onClick} sx={{padding:0,}}>
        <ArrowBackRoundedIcon sx={{color:"#1D6198", height, width}} />
    </IconButton>
}

export default ArrowBackBtn;