import { createSlice } from "@reduxjs/toolkit";
import {
  addNewMD,
  deleteMD,
  fetchMDs,
  updateMD,
  fetchMdById,
} from "../../slices/mds/mdsActions";
import { Statuses } from "../interfaces";
import { MD } from "../../../types/MD";

export interface MDState {
  MDs: MD[];
  error: string | any;
  status: Statuses;
}

const initialState: MDState = {
  MDs: [],
  error: null,
  status: "idle",
};

export const MDsSlice = createSlice({
  name: "MDs",
  initialState,
  reducers: {
    setLogout(state: MDState) {
      state.status = "idle";
      state.error = null;
      state.MDs = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMDs.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchMDs.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.sort(function (a: MD, b: MD) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        if (!action.payload.error) state.MDs = action.payload;
      })
      .addCase(fetchMDs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewMD.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          const index = state.MDs.findIndex(
            (md: MD) => md.id === action.payload.id
          );

          index === -1 && state.MDs.push(action.payload as unknown as MD);
        }
      })
      .addCase(deleteMD.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          state.MDs = state.MDs.filter(
            (MD: MD) => MD.id !== action.meta.arg.idToDelete
          );
        }
      })
      .addCase(updateMD.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.MDs.findIndex(
          (MDs) => MDs.id === action.payload.id
        );
        if (index !== -1) {
          state.MDs[index] = action.payload; // Replace the old item with the updated item
        } else {
          state.MDs.push(action.payload); // If the item doesn't exist, add the new item
        }
      })
      .addCase(updateMD.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateMD.rejected, (state, action) => {
        state.status = "failed";
        state.error = null;
      })
      .addCase(fetchMdById.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.MDs.findIndex(
          (MDs) => MDs.id === action.payload.id
        );
        if (index === -1) {
          state.MDs.push(action.payload); // If the item doesn't exist, add the new item
        }
      });
  },
});

type State = { MDs: MDState };
export const selectAllMDs = (state: State) => state.MDs.MDs;
export const selectMDsStatus = (state: State) => state.MDs.status;
export const selectMDsError = (state: State) => state.MDs.error;

export default MDsSlice.reducer;
