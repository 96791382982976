import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DocumentData } from "@google-cloud/firestore";
import { getAuth } from "firebase/auth";
import { Box, Zoom, useMediaQuery, useTheme } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import User from "src/types/User";
import { AppDispatch, RootState } from "src/redux/store";
import { useAppSelector } from "src/redux/hooks";
import { getAllUsers } from "src/redux/slices/users/userActions";
import FilledBtn from "src/components/Buttons/FilledBtn";
import Spinner from "src/components/Spinner/Spinner";
import UserCard from "src/components/Users/Card/UserCard";
import Modal from "src/components/Modal/Modal";

const UserManager: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const auth = getAuth();
  const { users } = useSelector((state: RootState) => state.userState);
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isDesktopSm = useMediaQuery(theme.breakpoints.between("md", "xl"));

  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState("");
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("loggedUser") ?? "")?.role ??
      loggedUser?.role
  );

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    action: "",
    title: "",
    content: "",
    function: () => {},
  });

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  const getUsers = async () => {
    try {
      const response: DocumentData = await dispatch(
        getAllUsers({ token, role })
      );
      if (response.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  useEffect(() => {
    getToken().then((res) => setToken(res ?? ""));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (token.length && role.length) {
      getUsers().finally(() => setLoading(false));
    }
  }, [token]);

  const renderedUsers = () => {
    if (users.length > 0) {
      return users.map((user: User, i: number) => (
        <Zoom
          in={true}
          style={{ transitionDelay: `${i * 50}ms` }}
          mountOnEnter
          unmountOnExit
          key={i}
        >
          <div>
            <UserCard user={user} token={token} role={role} />
          </div>
        </Zoom>
      ));
    } else {
      return (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">There are no results</h3>
        </div>
      );
    }
  };

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "20%" : "",
        paddingRight: isDesktop ? "20%" : "",
        boxSizing: "border-box",
      }}
    >
      <div
        className="d-flex justify-space-between align-center w100"
        style={{ height: "35px", marginBottom: "8px" }}
      >
        <h2
          className="font-title"
          style={{ lineHeight: "30px", fontSize: "24px" }}
        >
          Users
        </h2>

        <FilledBtn
          disabled={false}
          width={isDesktop ? "20%" : "30%"}
          fontSize="14px"
          text={"Add new"}
          onClick={() =>
            navigate("/users/create", {
              replace: false,
              state: { role, token },
            })
          }
        />
      </div>
      <div
        style={{
          borderTop: loading ? "none" : "1px solid #B7BED0",
          overflowY: loading ? "hidden" : isDesktop ? "auto" : "scroll",
          display: "flex",
          flexDirection: "column",
          marginTop: "4px",
          overflowX: "hidden",
          width: "100%",
          maxHeight: isDesktopSm ? "64vh" : "auto",
        }}
      >
        {loading ? (
          <div style={{ marginTop: 35 }}>
            <Spinner size={60} />
          </div>
        ) : (
          <Box>{renderedUsers()}</Box>
        )}
      </div>

      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "error" ? "Try again" : "Accept"}
        handleAction={modal.function}
        handleClose={
          modal.action === "success"
            ? modal.function
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};

export default UserManager;
