import "./Forbidden.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlockIcon from "@mui/icons-material/Block";
import { useAppSelector } from "src/redux/hooks";

const Forbidden = () => {
  const navigate = useNavigate();
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);

  useEffect(() => {
    if (!loggedUser) navigate("/login");
  });

  function handleReturn() {
    switch (loggedUser?.role) {
      case "admin":
        navigate("/home");
        break;

      case "cts":
        navigate("/patients");
        break;

      default:
        navigate("/users");
        break;
    }
  }

  return (
    <div style={{ height: "100vh" }}>
      <div className="forbidden-container">
        <BlockIcon color="disabled" sx={{ width: "120px", height: "120px" }} />
        <div className="forbidden-text">
          <h1 className="forbidden-title">Uh oh!</h1>
          <h2 className="forbidden-subtitle">
            Seems like you don't have permission to be here
          </h2>
          <h3 className="forbidden-content">
            The page you're looking for has restricted access, contact webmaster
            for additional information.
          </h3>
        </div>
        <button className="forbidden-button" onClick={handleReturn}>
          Back to home
        </button>
      </div>
    </div>
  );
};

export default Forbidden;
