import "./MDSManager.css";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DocumentData } from "@google-cloud/firestore";
import { getAuth } from "firebase/auth";
import { Zoom, useMediaQuery, useTheme, Box } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useAppSelector } from "src/redux/hooks";
import { MD } from "src/types/MD";
import { fetchMDs, addNewMD } from "src/redux/slices/mds/mdsActions";
import SearchInput from "src/components/Inputs/SearchInput";
import FilledBtn from "src/components/Buttons/FilledBtn";
import Spinner from "src/components/Spinner/Spinner";
import OutlinedBtn from "src/components/Buttons/OutlinedBtn";
import MDCard from "src/components/MD/Card/MDCard";
import Modal from "src/components/Modal/Modal";
const XLSX = require("xlsx");

export const MDSManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const mdsList = useAppSelector((s) => s.MDs.MDs);
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("loggedUser") ?? "")?.role ??
      loggedUser?.role
  );

  //MODAL
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    action: "",
    title: "",
    content: "",
    function: () => {},
  });

  const handleShowModal = () => {
    setIsOpen(!isOpen);
  };

  //SEARCH
  const [search, setSearch] = useState("");
  const [mdsDisplayed, setMDsDisplayed] = useState<MD[]>([]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  useEffect(() => {
    const lowerCaseTerm = search.toLowerCase();

    if (search) {
      const mdsSearch = mdsList.filter((mds: MD) => {
        return mds.name.toLowerCase().includes(lowerCaseTerm);
      });

      setMDsDisplayed(mdsSearch);
    } else {
      setMDsDisplayed(mdsList);
    }
  }, [search, mdsList]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const getMDS = async () => {
    setLoading(true);
    try {
      const response: DocumentData = await dispatch(fetchMDs({ token, role }));
      if (!response.payload.error) {
        if (response.payload?.length) {
          setLoading(false);
        }
      } else {
        setMDsDisplayed([]);
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setMDsDisplayed([]);
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const validateCSV = (csv: any) => {
      const requiredHeaders = ["Name"];
      const csvHeaders = XLSX.utils.sheet_to_json(csv, { header: 1 })[0];

      // Validate headers
      const areHeadersCorrect = requiredHeaders.every((header) =>
        csvHeaders.includes(header)
      );
      if (!areHeadersCorrect) {
        throw new Error(
          "Missing mandatory headers. Please check your CSV file."
        );
      }

      // Validate required fields
      const jsonData = XLSX.utils.sheet_to_json(csv);
      const rowsWithErrors: number[] = [];

      jsonData.forEach((row: any, index: number) => {
        const hasAllAttributes = requiredHeaders.every(
          (header) => !!row[header]
        );

        if (!hasAllAttributes) {
          rowsWithErrors.push(index + 2);
        }
      });

      if (rowsWithErrors.length > 0) {
        throw new Error(
          `The following row numbers have missing mandatory values:\n ${rowsWithErrors.join(
            ", "
          )}. Please check your CSV file.`
        );
      }
    };

    const file = e.target.files?.[0];

    if (
      file &&
      (file.type === "text/csv" || file.type === "application/vnd.ms-excel")
    ) {
      setLoading(true);
      try {
        const arrayBuffer = await file?.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        try {
          validateCSV(worksheet);
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          const parsedData = jsonData.map(
            (md: { [x: string]: any; Name: any }) => ({
              name: md["Name"],
              address: md["Address"] ?? "",
              phone: md["Phone"] ? md["Phone"].toString() : "",
              email: md["Email"] ?? "",
              poc: md["Point of contact"] ?? "",
              office_name: md["Name of office"] ?? "",
              validate: true,
            })
          );
          for (const mdData of parsedData) {
            try {
              await dispatch(addNewMD({ newMD: mdData }));
            } catch (error) {
              setModal({
                action: "error",
                title: "Uh oh!",
                content:
                  "Something went wrong. Please try uploading the file again.",
                function: () => {
                  setIsOpen(false);
                },
              });

              handleShowModal();
            }
          }
          setModal({
            action: "success",
            title: "Success!",
            content: "File uploaded successfully.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
          getMDS();
        } catch (error: any) {
          setModal({
            action: "error",
            title: "Uh oh!",
            content:
              "Something went wrong. Please try uploading the file again.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
        }
      } catch (error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "Something went wrong. Please try uploading the file again.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      } finally {
        setLoading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } else if (
      file &&
      !(file.type === "text/csv" || file.type === "application/vnd.ms-excel")
    ) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "Something went wrong. Please try uploading the file again.",
        function: () => {
          setIsOpen(false);
        },
      });

      handleShowModal();
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  // Init screen
  useEffect(() => {
    getToken().then((res) => setToken(res ?? ""));
  }, []);

  useEffect(() => {
    if (token.length && role.length) {
      getMDS();
    }
  }, [token]);

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      <div
        className="d-flex justify-space-between align-center w100"
        style={{ height: "35px", marginBottom: "8px" }}
      >
        <h2
          className="font-title"
          style={{ lineHeight: "30px", fontSize: "24px" }}
        >
          MDs
        </h2>
        <div className="d-flex flex-row" style={{ width: "75%", gap: "8px" }}>
          <OutlinedBtn
            text={"Upload CSV File"}
            clickFunction={handleUploadClick}
            disabled={false}
            width={"100%"}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".csv"
            onChange={handleFileUpload}
          />
          <FilledBtn
            disabled={false}
            width="55%"
            fontSize="14px"
            text={"Add new"}
            onClick={() => navigate("/mds-manager/create", { replace: false })}
          />
        </div>
      </div>
      <div className="w100" style={{ marginBottom: "4px" }}>
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          handleSearch={() => {}}
          height="35px"
        />
      </div>
      <div
        className="card-container md-list"
        style={{
          borderTop: loading ? "none" : "1px solid #B7BED0",
          overflowY: loading ? "hidden" : "auto",
        }}
      >
        {mdsDisplayed.some((md) => !md.validate) ? (
          <div>
            <Box>
              {mdsDisplayed.map((md: MD, i: number) => {
                if (!md.validate)
                  return (
                    <Zoom
                      in={true}
                      style={{ transitionDelay: `${i * 50}ms` }}
                      mountOnEnter
                      unmountOnExit
                      key={i}
                    >
                      <div>
                        <MDCard MDProps={md} token={token} role={role} />
                      </div>
                    </Zoom>
                  );
              })}
            </Box>
          </div>
        ) : undefined}
        <div
          style={{
            borderTop: loading ? "none" : "1px solid #B7BED0",
            overflowY: loading ? "hidden" : "auto",
          }}
        >
          {loading ? (
            <div style={{ marginTop: 35 }}>
              <Spinner size={60} />
            </div>
          ) : (
            <Box>
              {mdsDisplayed.length > 0 ? (
                mdsDisplayed.map((md: MD, i: number) => {
                  if (md.validate)
                    return (
                      <Zoom
                        in={true}
                        style={{ transitionDelay: `${i * 50}ms` }}
                        mountOnEnter
                        unmountOnExit
                        key={i}
                      >
                        <div>
                          <MDCard MDProps={md} token={token} role={role} />
                        </div>
                      </Zoom>
                    );
                })
              ) : (
                <div className="not-result-message">
                  <span className="material-icons-outlined">
                    <SearchOffIcon
                      sx={{
                        fontSize: "120px",
                        fontWeight: 100,
                        color: "#B7BED0",
                      }}
                    />
                  </span>
                  <h3 className="no-result-text">There are no results</h3>
                </div>
              )}
            </Box>
          )}
        </div>
      </div>
      <Box>
        <Modal
          open={isOpen}
          action={modal.action}
          title={modal.title}
          content={modal.content}
          btnActionText={modal.action === "error" ? "Try again" : "Accept"}
          handleAction={modal.function}
          handleClose={
            modal.action === "success"
              ? modal.function
              : () => {
                  setIsOpen(false);
                }
          }
        />
      </Box>
    </div>
  );
};

export default MDSManager;
