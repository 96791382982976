import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DocumentData } from "@google-cloud/firestore";
import { getAuth } from "firebase/auth";
import { Box, Zoom, useMediaQuery, useTheme } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { ClinicalTrial } from "src/types/ClinicalTrial";
import { AppDispatch, RootState } from "src/redux/store";
import { useAppSelector } from "src/redux/hooks";
import { getAllClinicalTrial } from "src/redux/slices/clinical-trials/clinicalTrialActions";
import SearchInput from "src/components/Inputs/SearchInput";
import FilledBtn from "src/components/Buttons/FilledBtn";
import Spinner from "src/components/Spinner/Spinner";
import CTCard from "src/components/CT/Card/CTCard";
import Modal from "src/components/Modal/Modal";

const OngoingCTs: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const auth = getAuth();
  const { clinicalTrials } = useSelector(
    (state: RootState) => state.clinicalTrials
  );
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [trialsDisplayed, setTrialsDisplayed] = useState<ClinicalTrial[]>([]);

  const [token, setToken] = useState("");
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("loggedUser") ?? "")?.role ??
      loggedUser?.role
  );

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    action: "",
    title: "",
    content: "",
    function: () => {},
  });

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  const getCT = async () => {
    try {
      const response: DocumentData = await dispatch(
        getAllClinicalTrial({ token, role })
      );
      if (!response.payload.error) {
        if (response.payload?.length) {
          setLoading(false);
        }
      } else {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  useEffect(() => {
    getToken().then((res) => setToken(res ?? ""));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (token.length && role.length) {
      getCT().finally(() => setLoading(false));
    }
  }, [token]);

  useEffect(() => {
    if (clinicalTrials.length > 0) {
      const orderedClinicalTrials = clinicalTrials.slice();

      orderedClinicalTrials.sort((a: ClinicalTrial, b: ClinicalTrial) => {
        if (a.ongoing === b.ongoing) return 0;
        else return a.ongoing ? -1 : 1;
      });

      setTrialsDisplayed(orderedClinicalTrials);
    }
  }, [clinicalTrials]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // Search
  useEffect(() => {
    if (clinicalTrials.length > 0) {
      const trialsToDisplay = clinicalTrials.filter(
        (ct: ClinicalTrial) => {
            return ct.name.toLowerCase().includes(search.toLowerCase());
        },
        [search]
      );

      const orderedClinicalTrials = trialsToDisplay.slice();

      orderedClinicalTrials.sort((a: ClinicalTrial, b: ClinicalTrial) => {
        if (a.ongoing === b.ongoing) return 0;
        else return a.ongoing ? -1 : 1;
      });

      setTrialsDisplayed(orderedClinicalTrials);
    }
  }, [search]);

  const renderedTrials = () => {
    if (trialsDisplayed.length > 0) {
      return trialsDisplayed.map((trial: ClinicalTrial, i: number) => (
        <Zoom
          in={true}
          style={{ transitionDelay: `${i * 50}ms` }}
          mountOnEnter
          unmountOnExit
          key={i}
        >
          <div>
            <CTCard trial={trial} token={token} role={role} />
          </div>
        </Zoom>
      ));
    } else {
      return (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">There are no results</h3>
        </div>
      );
    }
  };

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      <div
        className="d-flex justify-space-between align-center w100"
        style={{ height: "35px", marginBottom: "8px" }}
      >
        <h2
          className="font-title"
          style={{ lineHeight: "30px", fontSize: "24px" }}
        >
          Clinical trials
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: isDesktop ? "50%" : "30%",
          }}
        >
          {isDesktop && (
            <div style={{ width: "70%" }}>
              <SearchInput
                value={search}
                onChange={handleSearchChange}
                handleSearch={() => {}}
                height="32px"
              />
            </div>
          )}
          <FilledBtn
            disabled={false}
            width={isDesktop ? "30%" : "100%"}
            fontSize="14px"
            text={"Add new"}
            onClick={() =>
              navigate("/clinical-trials/create", {
                replace: false,
              })
            }
          />
        </div>
      </div>
      {!isDesktop && (
        <div className="w100" style={{ marginBottom: "4px" }}>
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            handleSearch={() => {}}
            height="32px"
          />
        </div>
      )}
      <div
        className="card-container"
        style={{
          borderTop: loading ? "none" : "1px solid #B7BED0",
          overflowY: loading ? "hidden" : isDesktop ? "auto" : "scroll",
        }}
      >
        {loading ? (
          <div style={{ marginTop: 35 }}>
            <Spinner size={60} />
          </div>
        ) : (
          <Box>{renderedTrials()}</Box>
        )}
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "error" ? "Try again" : "Accept"}
        handleAction={modal.function}
        handleClose={
          modal.action === "success"
            ? modal.function
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};

export default OngoingCTs;
