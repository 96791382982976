import "src/components/card-styles.css";
import "./CTSCard.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { ClinicalTrialSite } from "src/types/ClinicalTrialSite";
import { useAppDispatch } from "src/redux/hooks";
import { deleteCTS } from "src/redux/slices/clinical-trial-sites/ctsActions";
import Modal from "src/components/Modal/Modal";

interface CTSCardProps {
  ctsProps: ClinicalTrialSite;
  token: string;
  role: string;
}

const CTSCard: React.FC<CTSCardProps> = ({ ctsProps, token, role }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDelete = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setIsOpen(false);
    try {
      const result = await dispatch(
        deleteCTS({ idToDelete: ctsProps.id, token, role })
      );
      if (result.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            event.preventDefault();
            event.stopPropagation();
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  const handleEdit = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    navigate(`/cts-manager/edit/${ctsProps.id}`, {
      replace: false,
      state: { ctsProps, type: "edit", token, role },
    });
  };

  const handleClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    navigate(`/cts-manager/details/${ctsProps.id}`, {
      replace: false,
      state: { ctsProps, token, role },
    });
  };

  return (
    <div
      className="card"
      onClick={handleClick}
      style={{
        padding: isDesktop ? "8px 16px 8px 24px" : "",
      }}
    >
      <div className={"d-flex justify-space-between align-center"}>
        <h3 className={"cts-name"}>{ctsProps.name}</h3>
        <div>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setModal({
                action: "warning",
                title: "Are you sure?",
                content:
                  "You are about to delete the clinical trial site. This action cannot be undone.",
                function: () => handleDelete,
              });
              setIsOpen(true);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
          <IconButton size="small" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div className={"d-flex"}>
        <p className={"cts-postal-code"}>{ctsProps.zip_code}</p>
        <p className={"address"}>{`${ctsProps.address}, ${
          ctsProps.specifications && ctsProps.specifications.length
            ? ctsProps.specifications + ", "
            : ""
        } ${ctsProps.city}`}</p>
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "warning" ? "Delete" : "Try again"}
        handleClose={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        handleAction={
          modal.action === "error"
            ? (e) => {
                e.stopPropagation();
                setIsOpen(false);
              }
            : handleDelete
        }
      />
    </div>
  );
};

export default CTSCard;
