interface props {
  color: string;
}

const AmydisHomeIcon: React.FC<props> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 15"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0655 9.61346C10.165 9.40921 10.078 9.16411 9.87372 9.06464C9.66946 8.96518 9.42436 9.05221 9.3249 9.25647C9.22544 9.46072 9.31246 9.7076 9.51672 9.80528C9.72097 9.90475 9.96785 9.81772 10.0655 9.61346Z" />
      <path d="M8.66066 8.72876C8.83827 8.58668 8.86669 8.32914 8.7246 8.15153C8.58251 7.97392 8.32497 7.94372 8.14736 8.08581C7.96975 8.22612 7.94133 8.48544 8.08164 8.66305C8.22373 8.84066 8.48305 8.87085 8.66066 8.72876Z" />
      <path d="M7.77959 7.31959C8.0016 7.26986 8.14014 7.04962 8.08863 6.82761C8.0389 6.60559 7.81689 6.46706 7.59665 6.51856C7.37464 6.5683 7.2361 6.79031 7.28761 7.01055C7.33734 7.23256 7.55935 7.3711 7.77959 7.31959Z" />
      <path d="M7.5965 5.6692C7.81851 5.71893 8.03875 5.58217 8.08848 5.36016C8.13822 5.13814 8.00145 4.9179 7.77944 4.86817C7.55743 4.81667 7.33719 4.9552 7.28746 5.17722C7.23773 5.39923 7.37626 5.61947 7.5965 5.6692Z" />
      <path d="M8.14926 4.10089C8.32687 4.24297 8.58441 4.21278 8.7265 4.03517C8.86859 3.85756 8.83839 3.59825 8.66256 3.45616C8.48495 3.31584 8.22564 3.34426 8.08532 3.52187C7.94323 3.69948 7.97343 3.9588 8.15104 4.09911" />
      <path d="M9.3249 2.92987C9.42436 3.13412 9.66946 3.22115 9.87372 3.12169C10.078 3.024 10.165 2.77712 10.0655 2.57287C9.96785 2.36862 9.72097 2.28159 9.51672 2.38105C9.31246 2.47874 9.22544 2.72562 9.3249 2.92987Z" />
      <path d="M10.8931 2.38276C10.8931 2.6101 11.0778 2.79304 11.3051 2.79304C11.5325 2.79304 11.7154 2.60833 11.7154 2.38276C11.7154 2.1572 11.5307 1.9707 11.3051 1.9707C11.0796 1.9707 10.8931 2.15542 10.8931 2.38276Z" />
      <path d="M12.5451 2.57287C12.4457 2.77712 12.5327 3.02223 12.7369 3.12169C12.9412 3.22115 13.1863 3.13412 13.2858 2.92987C13.3834 2.72562 13.2982 2.47874 13.0939 2.38105C12.8897 2.28159 12.6446 2.36862 12.5451 2.57287Z" />
      <path d="M13.9501 3.45829C13.7725 3.60038 13.7441 3.85969 13.8844 4.0373C14.0265 4.21491 14.284 4.24333 14.4616 4.10302C14.6392 3.96093 14.6676 3.70161 14.5273 3.52578C14.3852 3.34817 14.1277 3.31975 13.9501 3.46006" />
      <path d="M14.831 4.86622C14.609 4.91595 14.4705 5.13619 14.522 5.3582C14.5717 5.58022 14.7937 5.71876 15.014 5.66725C15.236 5.61752 15.3745 5.39728 15.323 5.17526C15.2733 4.95325 15.0513 4.81471 14.831 4.86622Z" />
      <path d="M15.014 6.51801C14.792 6.46828 14.5717 6.60504 14.522 6.82706C14.4705 7.04907 14.609 7.26931 14.831 7.31904C15.0513 7.37055 15.2733 7.23201 15.323 7.01C15.3745 6.78976 15.236 6.56774 15.014 6.51801Z" />
      <path d="M14.4614 8.08581C14.2838 7.94372 14.0245 7.97392 13.8842 8.15153C13.7421 8.32914 13.7723 8.58668 13.9499 8.72876C14.1275 8.87085 14.3868 8.84066 14.5271 8.66305C14.6692 8.48544 14.639 8.22612 14.4614 8.08581Z" />
      <path d="M13.2858 9.25647C13.1863 9.05221 12.9412 8.96518 12.7369 9.06464C12.5327 9.16411 12.4457 9.40921 12.5451 9.61346C12.6446 9.81772 12.8897 9.90475 13.0939 9.80528C13.2982 9.70582 13.3852 9.46072 13.2858 9.25647Z" />
      <path d="M11.7154 9.80268C11.7154 9.57534 11.5307 9.39062 11.3033 9.39062C11.076 9.39062 10.8931 9.57534 10.8931 9.80268C10.8931 10.03 11.0778 10.213 11.3033 10.213C11.5289 10.213 11.7154 10.0282 11.7154 9.80268Z" />
      <path d="M12.9447 6.07881C12.9447 5.91185 12.911 5.752 12.8648 5.59926C12.7351 5.87988 12.4527 6.07881 12.1242 6.07881C11.6712 6.07881 11.3054 5.71115 11.3054 5.25824C11.3054 4.92788 11.5025 4.64726 11.7831 4.5176C11.6304 4.47142 11.4723 4.43945 11.3054 4.43945C10.3995 4.43945 9.66602 5.17299 9.66602 6.07881C9.66602 6.98463 10.3995 7.71816 11.3054 7.71816C12.2112 7.71816 12.9447 6.98463 12.9447 6.07881Z" />
      <path d="M11.0157 11.3939C9.28225 11.3548 7.55764 10.9605 5.88099 10.2234C4.41747 9.54496 2.49039 8.45797 0.541992 6.07798C2.48861 3.70332 4.4157 2.61456 5.88454 1.93253C7.55764 1.19544 9.28225 0.802923 11.0122 0.765625C10.1614 0.96455 9.31777 1.24873 8.48655 1.61283C6.95909 2.32505 4.94853 3.45999 2.92731 5.96786L2.83851 6.07798L2.92731 6.1881C4.95031 8.7013 6.95909 9.83446 8.48477 10.5431C9.316 10.9108 10.1632 11.195 11.0157 11.3939Z" />
    </svg>
  );
};

export default AmydisHomeIcon;
