import "react-phone-number-input/style.css";
import PhoneInput, { type Value } from "react-phone-number-input";
import { InputLabel } from "@mui/material";

interface TextInputProps {
  name: string;
  label: string;
  placeholder: string;
  value: Value;
  changeFunction: (value: Value | undefined) => void;
  required?: boolean;
  margin?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  placeholder,
  value,
  changeFunction,
  required = true,
  margin,
}: TextInputProps) => {
  const labelStyles = {
    fontFamily: "Karla, sans-serif",
    fontSize: "16px",
    letterSpacing: "1px",
    color: "rgba(5, 10, 12, 1)",
    textAlign: "left",
    margin: margin ?? "",
  };

  const handleChange = (value: Value | undefined) => {
    changeFunction(value);
  };

  return (
    <div>
      <InputLabel
        variant="standard"
        sx={labelStyles}
        required={required}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <PhoneInput
        defaultCountry="US"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        style={{
          height: "45px",
          ".PhoneInputInput": {
            padding: 0,
          },
        }}
      />
    </div>
  );
};

export default TextInput;
