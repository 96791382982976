import "./MDDetails.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, IconButton, Zoom, useMediaQuery, useTheme } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useAppDispatch } from "src/redux/hooks";
import { deleteMD } from "src/redux/slices/mds/mdsActions";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import Modal from "src/components/Modal/Modal";

export const MDDetails: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  // MODAL
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });

  const handleDelete = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);

    try {
      const response = await dispatch(
        deleteMD({
          idToDelete: state.mdProps.id,
          token: state.token,
          role: state.role,
        })
      );
      if (!response.payload.error) {
        setModal({
          action: "success",
          title: "Success!",
          content: "The MD was successfully deleted.",
          function: () => {
            navigate("/mds-manager");
            setIsOpen(false);
          },
        });
        setIsOpen(true);
      } else {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            event.preventDefault();
            event.stopPropagation();
            setIsOpen(false);
          },
        });
        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(false);
        },
      });
      setIsOpen(true);
    }
  };

  const handleEdit = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    navigate(`/mds-manager/edit/${state.mdProps.id}`, {
      replace: false,
      state: {
        mdProps: state.mdProps,
        type: "edit",
        token: state.token,
        role: state.role,
      },
    });
  };

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      <div
        className="d-flex justify-space-between align-center w100"
        style={{
          paddingBottom: isDesktop ? "4px" : "",
          borderBottom: isDesktop ? "1px solid #B7BED0" : "",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowBackBtn onClick={() => navigate("/mds-manager")} />
          <h2 className="font-title" style={{ marginLeft: "8px" }}>
            MD
          </h2>
        </div>
        <Box>
          <Modal
            open={isOpen}
            action={modal.action}
            title={modal.title}
            content={modal.content}
            btnActionText={
              modal.action === "warning"
                ? "Delete"
                : modal.action === "success"
                ? "Close"
                : "Try again"
            }
            handleAction={
              modal.action === "error"
                ? (e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }
                : modal.action === "warning"
                ? handleDelete
                : modal.function
            }
            handleClose={
              modal.action === "success"
                ? modal.function
                : (e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }
            }
          />
        </Box>
        {!state ? undefined : (
          <div>
            <IconButton
              size={isDesktop ? "large" : "small"}
              style={{ color: "#F7474B", padding: isDesktop ? "4px" : "" }}
              onClick={(e) => {
                e.stopPropagation();
                setModal({
                  action: "warning",
                  title: "Are you sure?",
                  content:
                    "You are about to delete the MD. This action cannot be undone.",
                  function: () => handleDelete,
                });
                setIsOpen(true);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
            <IconButton
              size={isDesktop ? "large" : "small"}
              style={{ color: "#1D6198", padding: isDesktop ? "4px" : "" }}
              onClick={handleEdit}
            >
              <EditIcon />
            </IconButton>
          </div>
        )}
      </div>
      {!state ? (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">MD not found</h3>
        </div>
      ) : (
        <Zoom
          in={true}
          style={{ transitionDelay: "100ms" }}
          mountOnEnter
          unmountOnExit
        >
          <div
            className="md-details w100 d-flex flex-column"
            style={{ alignItems: "start", marginTop: isDesktop ? "8px" : "" }}
          >
            <h3 className="cts-name">{state.mdProps?.name}</h3>
            <p className="info">
              <span className="label">Address:</span> {state.mdProps?.address}
            </p>
            <p className="info">
              <span className="label">Phone:</span> {state.mdProps?.phone}
            </p>
            <p className="info">
              <span className="label">Email:</span> {state.mdProps?.email}
            </p>
            <p className="info">
              <span className="label">Name of office:</span>{" "}
              {state.mdProps?.office_name}
            </p>
            <p className="info">
              <span className="label">Point of Contact:</span>{" "}
              {state.mdProps?.poc}
            </p>
          </div>
        </Zoom>
      )}
    </div>
  );
};

export default MDDetails;
