import "./FilterTab.css";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

interface FilterTabProps {
  activeFilter: string;
  setActiveFilter: (option: string) => void;
  options: string[];
  disabled: boolean;
  width?: number;
  blue?: boolean;
}

const FilterTab: React.FC<FilterTabProps> = ({
  activeFilter,
  setActiveFilter,
  options,
  disabled,
  width,
  blue = false,
}) => {
  
  // If width is provided, it needs to be a number so it can be divided into
  // the !== categories. If not provided, just "auto".
  let widthPerOption;
  width
    ? (widthPerOption = Math.floor(width / options.length))
    : (widthPerOption = "auto");

  const handleClick = (option: string) => {
    if (!disabled) setActiveFilter(option);
  };

  const renderedOptions = options.map((option) => (
    <div
      style={{
        width: width ? (widthPerOption as unknown as string) + "%" : "auto",
        textAlign: width ? "center" : "left",
        color: blue ? "#1D6198" : "auto",
        padding: blue ? "20px 0" : "auto",
        paddingBottom: blue ? "20px": "10px",
      }}
      key={option}
      className={`filter-option ${activeFilter === option ? "active" : ""} ${
        blue ? "blue" : ""
      }`}
      onClick={() => handleClick(option)}
    >
      {makeCapitalLetter(option)}
    </div>
  ));

  return (
    <div>
      <div className="filter-tab">{renderedOptions}</div>
    </div>
  );
};

export default FilterTab;
