import "src/pages/page-styles.css";
import "./CTSForm.css";
import "src/components/form-styles.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import { APIProvider } from "@vis.gl/react-google-maps";
import { DocumentData } from "@google-cloud/firestore";
import { Slide, useMediaQuery, useTheme } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { ClinicalTrialSite } from "src/types/ClinicalTrialSite";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  createCTS,
  updateCTS,
} from "src/redux/slices/clinical-trial-sites/ctsActions";
import TextInput from "src/components/Inputs/TextInput";
import PhoneInput from "src/components/Inputs/PhoneInput";
import Modal from "src/components/Modal/Modal";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import FilledBtn from "src/components/Buttons/FilledBtn";
import Spinner from "src/components/Spinner/Spinner";
import { PlaceAutocomplete } from "src/components/Inputs/PlaceAutocomplete/PlaceAutocomplete";
const { REACT_APP_MAPS_API } = process.env;

const CTSForm = () => {
  const dispatch = useAppDispatch();
  const cts = useAppSelector((s) => s.clinicalTrialSites.cts);
  const navigate = useNavigate();
  const { state } = useLocation();

  function trimCTS() {
    setFormData((formData) => {
      return {
        address: formData.address.trim(),
        city: formData.city.trim(),
        name: formData.name.trim(),
        phone: formData.phone.trim(),
        specifications:
          formData.specifications !== undefined
            ? formData.specifications.trim()
            : "",
        zip_code: formData.zip_code.trim(),
        email: formData.email.trim(),
      };
    });
    setIsTrimComplete(true);
  }

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });

  const handleShowModal = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value ?? "" });
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (firstSubmit) {
      setErrors({
        ...errors,
        name: validateName(event.target.value.toLowerCase().trim()),
      });
    }
    setFormData({ ...formData, name: event.target.value });
  };

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (firstSubmit) {
      setErrors({
        ...errors,
        address: validateAddress(
          event.target.value.trim().toLowerCase(),
          formData.specifications !== undefined
            ? formData.specifications.trim().toLowerCase()
            : ""
        ),
      });
    }
    setFormData({ ...formData, address: event.target.value });
  };

  const handleChangeSpecs = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (firstSubmit) {
      setErrors({
        ...errors,
        address: validateAddress(
          formData.address.trim().toLowerCase(),
          formData.specifications !== undefined
            ? event.target.value.trim().toLowerCase()
            : ""
        ),
      });
    }
    setFormData({ ...formData, specifications: event.target.value });
  };

  const handleChangePhone = (e: any) => {
    if (firstSubmit && e) {
      setErrors({
        ...errors,
        phone: validatePhone(e),
      });
    }
    setPhoneNumber(e);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (firstSubmit) {
      setErrors({
        ...errors,
        email: validateEmail(event.target.value.trim()),
      });
    }
    setFormData({ ...formData, email: event.target.value });
  };

  const [formData, setFormData] = useState<ClinicalTrialSite>({
    address: "",
    city: "",
    name: "",
    phone: "",
    specifications: "",
    zip_code: "",
    email: "",
  });
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isTrimComplete, setIsTrimComplete] = useState(false);

  // Validation
  const [errors, setErrors] = useState<{
    name: string | undefined;
    address: string | undefined;
    email: string | undefined;
    phone: string | undefined;
  }>({
    name: undefined,
    address: undefined,
    email: undefined,
    phone: undefined,
  });
  const [firstSubmit, setFirstSubmit] = useState<boolean>(false);

  const validateName = (name: string) => {
    if (name.length > 49)
      return "Please enter a name with less than 50 characters.";
    if (!state || (state && state?.ctsProps?.name.toLowerCase() !== name))
      if (
        cts.some((item: ClinicalTrialSite) => name === item.name.toLowerCase())
      )
        return "The name you entered is already in use. Please enter a new one.";
  };

  const validateAddress = (address: string, specifications: string) => {
    if (address.length > 99)
      return "The address must have less than 100 characters.";

    if (!state || !state.ctsProps) {
      if (
        cts.some((item: ClinicalTrialSite) => {
          // Check every address.
          return (
            item.address.toLowerCase() === address &&
            (!item.specifications.length ||
              !specifications.length ||
              item.specifications.toLowerCase() === specifications)
          );
        })
      )
        return "The address you entered is already in use. Please enter a new one.";
      else return;
    }

    const initialAddress = state.ctsProps.address.trim().toLowerCase();
    const initialSpecs =
      state.ctsProps.specifications !== undefined
        ? state.ctsProps.specifications.trim().toLowerCase()
        : "";

    if (
      state &&
      (address !== initialAddress || specifications !== initialSpecs)
    )
      if (
        cts.some((item: ClinicalTrialSite) => {
          if (
            item.name.trim().toLowerCase() !==
            state.ctsProps.name.trim().toLowerCase()
          )
            // Check only over CTS that are not the one being edited.
            return (
              item.address.toLowerCase() === address &&
              (!item.specifications.length ||
                !specifications.length ||
                item.specifications.toLowerCase() === specifications)
            );
        })
      )
        return "The address you entered is already in use. Please enter a new one.";
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[\w.-]+@amydis\.com$/;

    if (!emailRegex.test(email))
      return "Please enter a valid @amydis.com email.";

    if (!state || (state && state.ctsProps?.email.toLowerCase() !== email))
      if (
        cts.some(
          (item: ClinicalTrialSite) =>
            email.toLowerCase() === item.email.toLowerCase()
        )
      )
        return "The email is already by another CTS.";
  };

  const validatePhone = (phoneNumber: any) => {
    if (phoneNumber)
      if (!isValidPhoneNumber(phoneNumber))
        return "The phone is not valid. Please enter a valid phone number.";
  };

  const handleDisabledBtn = !(
    formData.name.trim().length > 0 &&
    formData.phone.trim().length > 0 &&
    formData.city.trim().length > 0 &&
    formData.address.trim().length > 0 &&
    formData.email.trim().length > 0 &&
    formData.zip_code.trim().length > 0
  );

  const handleCreate = () => {
    setLoading(true);
    dispatch(
      createCTS({
        ctsToCreate: {
          ...formData,
        },
        token: state.token,
        role: state.role,
      })
    )
      .then((response: DocumentData) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The clinical trial site was created successfully.",
            function: () => {
              navigate("/cts-manager");
              setIsOpen(false);
            },
          });

          handleShowModal();
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
        }
      })
      .catch(() => {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      });
  };

  const handleEdit = () => {
    setLoading(true);
    dispatch(
      updateCTS({
        idToUpdate: state.ctsProps.id,
        ctsUpdated: formData,
        token: state.token,
        role: state.role,
      })
    )
      .then((response: DocumentData) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The clinical trial site was updated successfully.",
            function: () => {
              navigate("/cts-manager");
              setIsOpen(false);
            },
          });

          handleShowModal();
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
        }
      })
      .catch(() => {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFirstSubmit(true);
    trimCTS();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isTrimComplete) {
      setIsTrimComplete(false);
      const error = {
        name: validateName(formData.name.toLowerCase()),
        address: validateAddress(
          formData.address.toLowerCase(),
          formData.specifications !== undefined
            ? formData.specifications.toLowerCase()
            : ""
        ),
        email: validateEmail(formData.email.toLowerCase()),
        phone: validatePhone(phoneNumber),
      };

      if (!error.name && !error.address && !error.email && !error.phone) {
        if (state && state.type === "edit") {
          handleEdit();
        } else {
          handleCreate();
        }
      } else {
        setErrors(error);

        setLoading(false);
      }
    }
  }, [isTrimComplete]);

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    let selectedAddress =
      selectedPlace?.formatted_address?.split(",").at(0) ?? "";
    let selectedCity = "";
    let selectedPostalCode = "";

    if (selectedPlace?.address_components) {
      let city = "";

      for (let component of selectedPlace.address_components) {
        if (component.types.includes("locality")) {
          city = component.long_name;
          selectedCity = city;
        }

        if (component.types.includes("administrative_area_level_1")) {
          selectedCity = `${city}, ${component.long_name}`;
        }

        if (component.types.includes("postal_code"))
          selectedPostalCode = component.long_name;
      }
    }

    setFormData((formData) => {
      return {
        ...formData,
        address: selectedAddress,
        city: selectedCity,
        zip_code: selectedPostalCode,
      };
    });
  }, [selectedPlace]);

  useEffect(() => {
    setFormData((formData) => {
      return { ...formData, phone: phoneNumber ?? "" };
    });
  }, [phoneNumber]);

  useEffect(() => {
    if (state && state?.type === "edit" && state?.ctsProps) {
      setPhoneNumber(state.ctsProps.phone);
      setFormData(state.ctsProps);
    }
  }, []);

  return (
    <>
      <div
        className="page-container"
        style={{
          paddingLeft: isDesktop ? "25%" : "",
          paddingRight: isDesktop ? "15%" : "",
          boxSizing: "border-box",
        }}
      >
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              boxSizing: "border-box",
              zIndex: 1001,
              width: "100%",
              background: "rgb(183, 190, 208,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : undefined}
        {window.location.href.includes("/edit/") && !state ? (
          <div className="not-result-message">
            <span className="material-icons-outlined">
              <SearchOffIcon
                sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
              />
            </span>
            <h3 className="no-result-text">Clinical trial site not found</h3>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            onKeyDown={handleKeyDown}
            className="w100"
          >
            <div className="d-flex justify-space-between align-center w100">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ArrowBackBtn onClick={() => navigate("/cts-manager")} />
                <h2 className="font-title" style={{ marginLeft: "8px" }}>
                  Clinical trial site
                </h2>
              </div>
              <FilledBtn
                width="77px"
                text="Save"
                disabled={handleDisabledBtn}
              />
            </div>
            <Slide in={true} direction="up" mountOnEnter unmountOnExit>
              <div
                className={"form-container w100 d-flex flex-column"}
                style={{
                  maxHeight: isDesktop ? "71vh" : "auto",
                }}
              >
                <div>
                  <TextInput
                    name={"name"}
                    label={"Name"}
                    placeholder={""}
                    value={formData.name || ""}
                    changeFunction={handleChangeName}
                    errorText={errors.name}
                    hasError={errors.name === undefined}
                    margin="0 0 .2em 0"
                  />
                  {errors.name ? (
                    <span className="cts-form-error">{errors.name}</span>
                  ) : undefined}
                </div>
                <APIProvider apiKey={REACT_APP_MAPS_API as string}>
                  <div>
                    <PlaceAutocomplete
                      onPlaceSelect={setSelectedPlace}
                      value={formData.address}
                      onChange={handleChangeAddress}
                    />
                  </div>
                  {errors.address ? (
                    <span className="cts-form-error">{errors.address}</span>
                  ) : undefined}
                </APIProvider>
                <TextInput
                  name={"specifications"}
                  label={"Apartment, suite, floor, etc."}
                  required={false}
                  placeholder={""}
                  value={formData.specifications || ""}
                  changeFunction={handleChangeSpecs}
                  margin=".7em 0 .2em 0"
                />
                <TextInput
                  name={"city"}
                  label={"City, State"}
                  placeholder={""}
                  value={formData.city || ""}
                  changeFunction={handleChange}
                  margin=".7em 0 .2em 0"
                />
                <div>
                  <TextInput
                    name={"zip_code"}
                    label={"Zip Code"}
                    placeholder={""}
                    value={formData.zip_code || ""}
                    changeFunction={handleChange}
                    margin=".7em 0 .2em 0"
                  />
                </div>
                <div>
                  <TextInput
                    name={"email"}
                    label={"Email"}
                    placeholder={""}
                    value={formData.email || ""}
                    changeFunction={handleChangeEmail}
                    margin=".7em 0 .2em 0"
                  />
                  {errors.email ? (
                    <span className="cts-form-error">{errors.email}</span>
                  ) : undefined}
                </div>
                <div>
                  <PhoneInput
                    name={"phone"}
                    label={"Phone"}
                    placeholder={""}
                    value={phoneNumber || ""}
                    changeFunction={handleChangePhone}
                    margin=".7em 0 0 0"
                  />
                  {errors.phone ? (
                    <span className="cts-form-error">{errors.phone}</span>
                  ) : undefined}
                </div>
              </div>
            </Slide>
          </form>
        )}

        <Modal
          open={isOpen}
          action={modal.action}
          title={modal.title}
          content={modal.content}
          btnActionText={modal.action === "error" ? "Try again" : "Accept"}
          handleAction={modal.function}
          handleClose={modal.function}
        />
      </div>
    </>
  );
};

export default CTSForm;
