import "./CTCard.css";
import "src/components/card-styles.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Modal from "src/components/Modal/Modal";
import { ClinicalTrial } from "src/types/ClinicalTrial";
import { useAppDispatch } from "src/redux/hooks";
import { deleteClinicalTrial } from "src/redux/slices/clinical-trials/clinicalTrialActions";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

interface CTCardProps {
  trial: ClinicalTrial;
  token: string;
  role: string;
}

const CTCard: React.FC<CTCardProps> = ({ trial, token, role }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Modal
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(`details/${trial.id}`, { state: { trial, token, role } });
  };

  const handleDelete = async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setIsOpen(false);
    try {
      const result = await dispatch(
        deleteClinicalTrial({ idToDelete: trial.id, token, role })
      );
      if (result.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  const handleEdit = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(`edit/${trial.id}`, {
      replace: false,
      state: { trial: trial, type: "edit", token, role },
    });
  };

  return (
    <div className="card" onClick={handleClick}>
      <div className="trial-header">
        <div className="trial-text">
          <span className="material-symbols-outlined trial-icon">
            {trial.icon}
          </span>
          <h3>{makeCapitalLetter(trial.name)}</h3>
        </div>
        <div className="trial-right-side">
          <div>
            {trial.ongoing ? (
              <h4 className="trial-ongoing">ongoing</h4>
            ) : undefined}
          </div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setModal({
                action: "warning",
                title: "Are you sure?",
                content:
                  "You are about to delete the clinical trial. This action cannot be undone.",
                function: () => handleDelete,
              });
              setIsOpen(true);
            }}
            sx={{ padding: 0 }}
          >
            <DeleteOutlineIcon
              sx={{ color: "black", height: "20px", fontWeight: 100 }}
            />
          </IconButton>
          <IconButton onClick={handleEdit} sx={{ padding: 0 }}>
            <EditIcon
              sx={{ color: "black", height: "20px", fontWeight: 100 }}
            />
          </IconButton>
        </div>
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "warning" ? "Delete" : "Try again"}
        handleClose={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        handleAction={
          modal.action === "error"
            ? (e) => {
                e.stopPropagation();
                setIsOpen(false);
              }
            : handleDelete
        }
      />
    </div>
  );
};

export default CTCard;
