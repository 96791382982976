import * as React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { useAuth } from "src/contexts/AuthContext";

const useStyles = makeStyles()((theme) => {
  return {
    profileIcon: {
      [theme.breakpoints.up(100)]: {
        height: "1em",
        width: "1em",
      },
      [theme.breakpoints.up(400)]: {
        height: "1.5em",
        width: "1.5em",
      },
      [theme.breakpoints.up(700)]: {
        height: "2em",
        width: "2em",
      },
    },
  };
});

const UserMenu = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { classes } = useStyles();
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setInitials = () => {
    const arr = currentUser.name.split(" ");
    return `${arr[0][0]}${arr[1][0]}`.toUpperCase();
  };

  const setLogout = () => {
    logout();
    navigate("login");
  };

  return (
    <>
      <Box>
        <IconButton
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className={classes.profileIcon}
        >
          <Avatar>{setInitials()} </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          "& .MuiMenuItem-root": {
            fontFamily: "Karla, serif",
            color: "rgba(5, 10, 12, 1)",
            padding: "8px 16px",
          },

          "& .MuiPaper-root": {
            minWidth: "200px",
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div
          style={{
            padding: "8px 16px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <p>{currentUser.name}</p>
          <p style={{ fontSize: "14px" }}>{currentUser.email}</p>
        </div>

        <MenuItem
          onClick={setLogout}
          sx={{
            gap: "10px",
          }}
        >
          <ListItemIcon>
            <Logout
              className={classes.profileIcon}
              sx={{
                color: "rgba(29, 97, 152, 1)",
                fontSize: isDesktop ? "1rem" : "1.8rem",
              }}
            />
          </ListItemIcon>
          <p
            style={{
              color: "rgba(29, 97, 152, 1)",
              fontSize: "14px",
              fontWeight: 700,
              margin: 0,
            }}
          >
            Sign out
          </p>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
