import React, { ReactNode } from "react";
import { Button as BaseButton, buttonClasses } from "@mui/material";
import { styled } from "@mui/system";

interface BtnProps {
  text: ReactNode;
  disabled: boolean | undefined;
  type?: "submit" | "button" | "reset" | undefined;
  isFullWidth?: boolean;
  fontSize?: string;
  width?: string;
  lineHeight?: string;
  onClick?: (e : React.MouseEvent<HTMLElement> ) => void;
  backgroundColor?: string;
}

const Button = styled(BaseButton)(
  () => `
  font-family: "DM Sans", sans-serif;
  background: rgba(29, 97, 152, 1);
  border-radius: 100px;
  text-transform: none;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  letter-spacing: 0.5px;

  &:hover {
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);  
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    background: rgba(120, 157, 202, 1);
  }

  &.${buttonClasses.focusVisible} {
    background: rgba(255, 255, 255, 0.12);
  }

  &.${buttonClasses.disabled} {
    background: rgba(31, 31, 31, 0.12);
    color: rgba(28, 27, 31, .38);
  }
`
);

const FilledBtn: React.FC<BtnProps> = ({
  text,
  disabled,
  type = "submit",
  isFullWidth = true,
  fontSize = "14px",
  width = "",
  lineHeight,
  onClick,
  backgroundColor,
}: BtnProps) => {
  return (
    <Button
      fullWidth={isFullWidth}
      disabled={disabled}
      type={type}
      style={{
        fontSize: fontSize,
        width: width,
        lineHeight,
        backgroundColor,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default FilledBtn;
