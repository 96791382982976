import "./TopBar.css";
// @ts-ignore
import logo from "src/assets/logo-amydis.png";
import UserMenu from "src/components/UserMenu/UserMenu";

const TopBar = () => {
  const handleProfileClick = () => {};

  return (
    <div className="top-bar">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="profile-icon" onClick={handleProfileClick}>
        <div>
          <UserMenu />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
