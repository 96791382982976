import "./BottomNav.css";
import { NavLink } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import { useAuth } from "src/contexts/AuthContext";
import AmydisHomeIcon from "src/components/AmydisHomeIcon/AmydisHomeIcon";

const useStyles = makeStyles()((theme) => {
  return {
    profileIcon: {
      [theme.breakpoints.up(768)]: {
        height: "2em",
        width: "2em",
      },
    },
  };
});

function BottomNav() {
  const { classes } = useStyles();
  const { currentUser } = useAuth();

  return (
    <div className="bottom-nav-container">
      {currentUser.role === "cts" ? (
        <nav className="bottom-nav">
          <NavLink to="/patients" className="nav-item">
            <PersonOutlineIcon
              fontSize={"small"}
              className={classes.profileIcon}
            />
            <p>Patient Database</p>
          </NavLink>
          <NavLink to="/mds-manager" className="nav-item">
            <MedicalInformationOutlinedIcon
              fontSize={"small"}
              className={classes.profileIcon}
            />
            <p>Manage MDs</p>
          </NavLink>
        </nav>
      ) : (
        <nav className="bottom-nav">
          <NavLink to="/home" className="nav-item">
            {({ isActive }) => (
              <div>
                <AmydisHomeIcon color={isActive ? "#B7BED0" : "white"} />
                <p>Home</p>
              </div>
            )}
          </NavLink>
          <NavLink to="/cts-manager" className="nav-item">
            <LocalHospitalOutlinedIcon
              fontSize={"small"}
              className={classes.profileIcon}
            />
            <p>Manage CTS</p>
          </NavLink>
          <NavLink to="/mds-manager" className="nav-item">
            <MedicalInformationOutlinedIcon
              fontSize={"small"}
              className={classes.profileIcon}
            />
            <p>Manage MDs</p>
          </NavLink>
          <NavLink to="/clinical-trials" className="nav-item">
            <ListAltIcon fontSize={"small"} className={classes.profileIcon} />
            <p>Manage CTs</p>
          </NavLink>
        </nav>
      )}
    </div>
  );
}

export default BottomNav;
