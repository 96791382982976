import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Patient from "../../../types/Patient";
const { REACT_APP_BACKEND_BASE_URL } = process.env;

export const fetchPatientsAmountByCts: any = createAsyncThunk(
  "patient/fetchPatientsAmountByCts",
  async ({ token, role }: { token: string; role: string }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/patient/getPatientsAmountByCTS`;
      const patientsResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return patientsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const fetchPatientsAmountByMd: any = createAsyncThunk(
  "patient/fetchPatientsAmountByMd",
  async ({ token, role }: { token: string; role: string }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/patient/getPatientsAmountByMD`;
      const patientsResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });

      return patientsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const fetchPatientsByCTS: any = createAsyncThunk(
  "patient/fetchPatientsByCTS",
  async ({
    ctsId,
    token,
    role,
  }: {
    ctsId: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/patient/getAllPatientsByCTS/${ctsId}`;
      const patientsResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return patientsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const deletePatient: any = createAsyncThunk(
  "patients/deletePatient",
  async ({
    patientIdToDelete,
    token,
    role,
  }: {
    patientIdToDelete: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/patient/delete/${patientIdToDelete}`;
      const response = await axios.delete<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const updatePatient: any = createAsyncThunk(
  "patient/updatePatient",
  async ({
    idToUpdate,
    patientUpdated,
    token,
    role,
  }: {
    idToUpdate: string;
    patientUpdated: Patient;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/patient/updatePatient/${idToUpdate}`;
      const patientResponse = await axios.put<any>(url, patientUpdated, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return patientResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);
