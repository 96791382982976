import "src/components/card-styles.css";
import "./UserCard.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import User from "src/types/User";
import { useAppDispatch } from "src/redux/hooks";
import { deleteUser } from "src/redux/slices/users/userActions";
import Modal from "src/components/Modal/Modal";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

interface UserCardProps {
  user: User;
  token: string;
  role: string;
}

const UserCard: React.FC<UserCardProps> = ({ user, token, role }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });

  const handleDelete = async (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);

    try {
      const result = await dispatch(
        deleteUser({ idToDelete: user.id, token, role })
      );
      if (result.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  const handleEdit = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate(`edit/${user.id}`, {
      replace: false,
      state: { user: user, type: "edit", token, role },
    });
  };

  return (
    <div className="card">
      <div className="user-card">
        <div className="content-container-up">
          <h3 style={{ margin: 0 }}>{user.email}</h3>
          {user.role !== "master" ? (
            <div style={{ height: "24px" }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    action: "warning",
                    title: "Are you sure?",
                    content:
                      "You are about to delete the user. This action cannot be undone.",
                    function: () => handleDelete,
                  });
                  setIsOpen(true);
                }}
                sx={{ padding: 0 }}
              >
                <DeleteOutlineIcon
                  sx={{ color: "black", height: "20px", fontWeight: 100 }}
                />
              </IconButton>
              <IconButton onClick={handleEdit} sx={{ padding: 0 }}>
                <EditIcon
                  sx={{ color: "black", height: "20px", fontWeight: 100 }}
                />
              </IconButton>
            </div>
          ) : (
            <div style={{ height: "24px" }}></div>
          )}
        </div>

        <div className="content-container-down">
          {makeCapitalLetter(user.role)}
        </div>
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "warning" ? "Delete" : "Try again"}
        handleClose={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        handleAction={
          modal.action === "error"
            ? (e) => {
                e.stopPropagation();
                setIsOpen(false);
              }
            : handleDelete
        }
      />
    </div>
  );
};

export default UserCard;
