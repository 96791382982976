import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import User from "../../../types/User";
const { REACT_APP_BACKEND_BASE_URL } = process.env;

export const getAllUsers : any = createAsyncThunk(
  "users/getAllUsers",
  async ({ token, role }: { token: string; role: string }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/users/getAllUsers`;
      const response = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const createUser: any = createAsyncThunk(
  "users/createUser",
  async ({
    userToCreate,
    token,
    role,
  }: {
    userToCreate: User;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/users/createUser`;
      const response = await axios.post<any>(url, userToCreate, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const updateUser: any = createAsyncThunk(
  "users/updateUser",
  async (
    {
      idToUpdate,
      userUpdated,
      token,
      role,
    }: { idToUpdate: string; userUpdated: User; token: string; role: string },
    thunkAPI
  ) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/users/updateUser/${idToUpdate}`;
      const response = await axios.put<any>(url, userUpdated, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const deleteUser: any = createAsyncThunk(
  "users/deleteUser",
  async ({
    idToDelete,
    token,
    role,
  }: {
    idToDelete: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/users/deleteUser/${idToDelete}`;
      const response = await axios.delete<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const getUserById: any = createAsyncThunk(
  "users/getUserById",
  async ({ id, token, role }: { id: string; token: string; role: string }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/users/getUserById/${id}`;
      const userResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return userResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const getUserByEmail: any = createAsyncThunk(
  "users/getUserByEmail",
  async ({
    email,
    token,
    role,
  }: {
    email: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/users/getUserByEmail/${email}`;
      const userResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return userResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);
