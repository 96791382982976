import { ReactNode } from "react";
import { InputLabel, Select, SelectChangeEvent } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

interface SelectInputProps {
  name: string;
  label: string;
  placeholder: string;
  value: string;
  items: any;
  changeFunction: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  required?: boolean;
  errorText?: string;
  hasError?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  label,
  placeholder,
  value,
  items,
  changeFunction,
  required = true,
  errorText = " ",
  hasError = false,
}: SelectInputProps) => {
  const inputStyles = {
    fontFamily: "DM Sans, sans-serif",
    background: "white",
    borderRadius: "8px",
    fontSize: "14px",
    color: "rgba(5, 10, 12, 1)",
    height: "2.5em",
    textAlign: "left",
    letterSpacing: "0.5px",

    "& .MuiInputBase-root": {
      fontFamily: "DM Sans, sans-serif",
      background: "white",
      borderRadius: "8px",
      fontSize: "14px",
      color: "rgba(5, 10, 12, 1)",
      height: "2.5em",
      letterSpacing: "0.5px",
      textAlign: "left",
    },
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "rgba(29, 97, 152, 1) !important",
    },
    "& input": {
      marginBottom: "0",
    },
  };

  const labelStyles = {
    fontFamily: "Karla, sans-serif",
    fontSize: "12px",
    letterSpacing: "1px",
    color: "rgba(5, 10, 12, 1)",
    textAlign: "left",
    marginBottom: "0.2em",
  };

  return (
    <div>
      <InputLabel
        variant="standard"
        sx={labelStyles}
        required={required}
        id={name}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <Select
        name={name}
        labelId={name}
        required
        value={value}
        onChange={changeFunction}
        fullWidth
        IconComponent={KeyboardArrowDownIcon}
        sx={inputStyles}
        renderValue={(selected) => {
          if (selected.length === 0 || !selected) {
            return placeholder;
          }

          return makeCapitalLetter(selected);
        }}
        children={items}
        error={hasError}
      />
      <FormHelperText
        sx={{
          fontFamily: "DM Sans, sans-serif",
          marginRight: 0,
          marginLeft: "2px",
          fontSize: "0.65rem",
          color: "#F7474B",
        }}
      >
        {errorText}
      </FormHelperText>
    </div>
  );
};

export default SelectInput;
