import React from "react";
import { Button as BaseButton, buttonClasses } from "@mui/material";
import { styled } from "@mui/system";

interface BtnProps {
  text: String;
  clickFunction: (e : React.SyntheticEvent | undefined) => void;
  disabled: boolean | undefined;
  isFullWidth?: boolean;
  width?: string;
  borderColor?: string;
  color?: string;
}

const Button = styled(BaseButton)(
  () => `
  font-family: "DM Sans", sans-serif;
  border: 1px solid rgba(29, 97, 152, 1);
  background: transparent;
  color: rgba(29, 97, 152, 1);
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: none;

  &:hover {
    background-color: rgba(103, 80, 164, 0.08);
  }

  &.${buttonClasses.focusVisible} {
    background: rgba(103, 80, 164, 0.12);
  }

  &.${buttonClasses.disabled} {
    border: 1px solid rgba(31, 31, 31, 0.12);
    color: rgba(28, 27, 31, 0.28);
  }
`
);
const OutlinedBtn: React.FC<BtnProps> = ({
  text,
  clickFunction,
  disabled,
  isFullWidth=true,
  width="",
  borderColor= "#1D6198",
  color="#1D6198"
}: BtnProps) => {
  return (
    <Button fullWidth={isFullWidth} onClick={clickFunction} disabled={disabled}
    style={{width, borderColor, color}}>
      {text}
    </Button>
  );
};

export default OutlinedBtn;
