import { InputLabel, TextField } from "@mui/material";

interface TextInputProps {
  name: string;
  label: string;
  placeholder: string;
  type?: string;
  value: string;
  changeFunction: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  required?: boolean;
  height?: string;
  labelSize?: string;
  multiline?: boolean;
  margin?: string;
  errorText?: string;
  hasError?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  placeholder,
  type = "text",
  value,
  changeFunction,
  required = true,
  height = "auto",
  labelSize = "16px",
  multiline = false,
  margin,
  errorText = "",
  hasError = false,
}: TextInputProps) => {
  const inputStyles = {
    fontFamily: "DM Sans, sans-serif",
    borderRadius: "8px",
    fontSize: "14px",
    color: "rgba(5, 10, 12, 1)",
    height,
    textAlign: "left",
    letterSpacing: "0.5px",

    "& .MuiInputBase-root": {
      fontFamily: "DM Sans, sans-serif",
      background: "white",
      borderRadius: "8px",
      fontSize: "16px",
      color: "rgba(5, 10, 12, 1)",
      height,
      letterSpacing: "0.5px",
      textAlign: "left",
      padding: multiline ? "8px" : "auto",
    },
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "rgba(29, 97, 152, 1) !important",
    },
    "& input": {
      marginBottom: "0",
      width: "100%",
    },
    "& .MuiFormHelperText-root, .MuiFormHelperText-root.Mui-error": {
      fontFamily: "DM Sans, sans-serif",
      marginRight: 0,
      marginLeft: "2px",
      fontSize: "0.65rem",
      color: "#F7474B",
    },
  };

  const labelStyles = {
    fontFamily: "Karla, sans-serif",
    letterSpacing: "1px",
    color: "rgba(5, 10, 12, 1)",
    textAlign: "left",
    marginBottom: "0.2em",
    marginTop: "0",
    margin: margin ?? "",
    fontSize: labelSize,
  };

  return (
    <div>
      <InputLabel
        variant="standard"
        sx={labelStyles}
        required={required}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <TextField
        required={required}
        value={value}
        placeholder={placeholder}
        onChange={changeFunction}
        fullWidth
        sx={{ ...inputStyles }}
        name={name}
        type={type}
        multiline={multiline}
        minRows={4}
        maxRows={10}
        helperText={errorText}
        error={hasError}
      ></TextField>
    </div>
  );
};

export default TextInput;
