import { createSlice } from "@reduxjs/toolkit";
import {
  deletePatient,
  fetchPatientsAmountByCts,
  fetchPatientsAmountByMd,
  fetchPatientsByCTS,
} from "./patientActions";
import { Statuses } from "../interfaces";
import Patient from "../../../types/Patient";

export interface PatientState {
  patientsByCTS: Patient[];
  patientsAmountByCTS: { cts: string; patients: number }[];
  patientsAmountByMD: { md: string; patients: number }[];
  patientsTotal: number;
  error: string | any;
  status: Statuses;
}

const initialState: PatientState = {
  patientsByCTS: [],
  patientsAmountByCTS: [],
  patientsAmountByMD: [],
  patientsTotal: null,
  error: null,
  status: "idle",
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setLogout(state: PatientState) {
      state.status = "idle";
      state.error = null;
      state.patientsByCTS = [];
      state.patientsAmountByCTS = [];
      state.patientsAmountByMD = [];
      state.patientsTotal = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientsAmountByCts.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPatientsAmountByCts.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.error) {
          state.patientsAmountByCTS = action.payload.map(
            (el: { cts: string; patients: number }) => ({
              cts: el.cts,
              patients: el.patients,
            })
          );
          if (action.payload.length)
            state.patientsTotal = action.payload[0].total;
        }
      })
      .addCase(fetchPatientsAmountByCts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPatientsAmountByMd.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPatientsAmountByMd.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.error) {
          state.patientsAmountByMD = action.payload.map(
            (el: { md: string; patients: number }) => ({
              md: el.md,
              patients: el.patients,
            })
          );

          if (
            action.payload.length &&
            state.patientsTotal !== action.payload[0].total
          )
            state.patientsTotal = action.payload[0].total;
        }
      })
      .addCase(fetchPatientsAmountByMd.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPatientsByCTS.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPatientsByCTS.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.patientsByCTS = action.payload;
      })
      .addCase(fetchPatientsByCTS.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          state.patientsByCTS = state.patientsByCTS.filter(
            (patient: Patient) =>
              patient.id !== action.meta.arg.patientIdToDelete
          );
        }
      });
  },
});

type State = { patients: PatientState };
export const selectUsersStatus = (state: State) => state.patients.status;
export const selectUsersError = (state: State) => state.patients.error;

export default patientsSlice.reducer;
