import "./SideNav.css";
import { useNavigate, useLocation } from "react-router-dom";
import { MenuList, MenuItem, ListItemText } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import { useAuth } from "src/contexts/AuthContext";
import AmydisHomeIcon from "src/components/AmydisHomeIcon/AmydisHomeIcon";

function SideNav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser } = useAuth();

  const menuItemStyle = {
    color: "white",
    gap: "10px",
    textAlign: "left",
    "&:active, &:focus, &:focus-visible, &.Mui-selected": {
      color: "#1D6198",
      background: "rgba(255, 255, 255, 0.2)",
    },
    "&:hover, &.Mui-selected:hover": {
      background: "#d4d4d43b",
    },
  };
  return (
    <div className="side-nav-container">
      {currentUser.role === "cts" ? (
        <MenuList sx={{ marginTop: "4em" }}>
          <MenuItem
            sx={menuItemStyle}
            onClick={() => navigate("/patients")}
            selected={pathname.includes("patients")}
          >
            <PersonOutlineIcon fontSize="small" />
            <ListItemText>Patient Database</ListItemText>
          </MenuItem>
          <MenuItem
            sx={menuItemStyle}
            onClick={() => navigate("/mds-manager")}
            selected={pathname.includes("mds-manager")}
          >
            <MedicalInformationOutlinedIcon fontSize="small" />
            <ListItemText>Manage MDs</ListItemText>
          </MenuItem>
        </MenuList>
      ) : (
        <MenuList sx={{ marginTop: "4em" }}>
          <MenuItem
            sx={menuItemStyle}
            onClick={() => navigate("/home")}
            selected={pathname.includes("home")}
          >
            <AmydisHomeIcon
              color={pathname.includes("home") ? "#1D6198" : "white"}
            />
            <ListItemText>Home</ListItemText>
          </MenuItem>
          <MenuItem
            sx={menuItemStyle}
            onClick={() => navigate("/cts-manager")}
            selected={pathname.includes("cts-manager")}
          >
            <LocalHospitalOutlinedIcon fontSize="small" />
            <ListItemText>Manage CTS</ListItemText>
          </MenuItem>
          <MenuItem
            sx={menuItemStyle}
            onClick={() => navigate("/mds-manager")}
            selected={pathname.includes("mds-manager")}
          >
            <MedicalInformationOutlinedIcon fontSize="small" />
            <ListItemText>Manage MDs</ListItemText>
          </MenuItem>

          <MenuItem
            sx={menuItemStyle}
            onClick={() => navigate("/clinical-trials")}
            selected={pathname.includes("clinical-trials")}
          >
            <ListAltIcon fontSize="small" />
            <ListItemText>Manage CTs</ListItemText>
          </MenuItem>
        </MenuList>
      )}
    </div>
  );
}

export default SideNav;
