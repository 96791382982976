import "./PlaceAutocomplete.css";
import { useEffect, useRef, useState } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { InputLabel } from "@mui/material";

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PlaceAutocomplete = ({
  onPlaceSelect,
  value,
  onChange,
}: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address", "address_components"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <InputLabel
        variant="standard"
        sx={{
          fontFamily: "Karla, sans-serif",
          letterSpacing: "1px",
          color: "rgba(5, 10, 12, 1)",
          textAlign: "left",
          marginBottom: "0.2em",
          marginTop: "0.7em",
          fontSize: "16px",
        }}
        required={false}
      >
        {"Address *"}
      </InputLabel>
      <input
        placeholder=""
        ref={inputRef}
        className="placeautocomplete"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
