import "src/pages/page-styles.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DocumentData } from "@google-cloud/firestore";
import { getAuth } from "firebase/auth";
import { Zoom, useMediaQuery, useTheme, Box } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { ClinicalTrialSite } from "src/types/ClinicalTrialSite";
import { useAppSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import { getAllCTS } from "src/redux/slices/clinical-trial-sites/ctsActions";
import CTSCard from "src/components/CTS/Card/CTSCard";
import SearchInput from "src/components/Inputs/SearchInput";
import FilledBtn from "src/components/Buttons/FilledBtn";
import Spinner from "src/components/Spinner/Spinner";
import Modal from "src/components/Modal/Modal";

export const CTSManager = () => {
  const dispatch = useDispatch();
  const ctsList = useAppSelector(
    (state: RootState) => state.clinicalTrialSites.cts
  );
  const navigate = useNavigate();
  const auth = getAuth();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const loggedUser = useAppSelector((s) => s.userState.loggedUser);
  const [token, setToken] = useState("");
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("loggedUser") ?? "")?.role ??
      loggedUser?.role
  );

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    action: "",
    title: "",
    content: "",
    function: () => {},
  });

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const [ctsDisplayed, setCtsDisplayed] = useState<ClinicalTrialSite[]>([]);

  const { status } = useSelector(
    (state: RootState) => state.clinicalTrialSites
  );

  useEffect(() => {
    const lowerCaseTerm = search.toLowerCase();

    if (search) {
      const ctsSearched = ctsList.filter((cts: ClinicalTrialSite) => {
        return (
          cts.address.toLowerCase().includes(lowerCaseTerm) ||
          cts.city.toLowerCase().includes(lowerCaseTerm) ||
          cts.name.toLowerCase().includes(lowerCaseTerm) ||
          String(cts.zip_code).toLowerCase().includes(lowerCaseTerm)
        );
      });

      setCtsDisplayed(ctsSearched);
    } else {
      setCtsDisplayed(ctsList);
    }
  }, [search]);

  const getCTS = async () => {
    setLoading(true);
    try {
      const response: DocumentData = await dispatch(getAllCTS());
      if (!response.payload.error) {
        setLoading(false);
      } else {
        setLoading(false);

        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setLoading(false);

      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  useEffect(() => {
    getToken().then((res) => setToken(res ?? ""));
  }, []);

  useEffect(() => {
    if (token.length && role.length) getCTS();
  }, [token, role]);

  useEffect(() => {
    return setCtsDisplayed(ctsList);
  }, [ctsList]);

  const renderedCts = () => {
    if (ctsDisplayed.length > 0) {
      return ctsDisplayed.map((cts: ClinicalTrialSite, i: number) => (
        <Zoom
          in={true}
          style={{ transitionDelay: `${i * 50}ms` }}
          mountOnEnter
          unmountOnExit
          key={i}
        >
          <div>
            <CTSCard ctsProps={cts} token={token} role={role} />
          </div>
        </Zoom>
      ));
    } else {
      return (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">There are no results</h3>
        </div>
      );
    }
  };

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      <div
        className="d-flex justify-space-between align-center w100"
        style={{ height: "35px", marginBottom: "8px" }}
      >
        <h2
          className="font-title"
          style={{ lineHeight: "30px", fontSize: "24px" }}
        >
          Clinical trial sites
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: isDesktop ? "50%" : "30%",
          }}
        >
          {isDesktop && (
            <div style={{ width: "70%" }}>
              <SearchInput
                value={search}
                onChange={handleSearchChange}
                handleSearch={() => {}}
                height="32px"
              />
            </div>
          )}
          <FilledBtn
            disabled={false}
            width={isDesktop ? "30%" : "100%"}
            fontSize="14px"
            text={"Add new"}
            onClick={() =>
              navigate("/cts-manager/create", {
                replace: false,
                state: { token, role },
              })
            }
          />
        </div>
      </div>
      {!isDesktop && (
        <div className="w100" style={{ marginBottom: "4px" }}>
          <SearchInput
            value={search}
            onChange={handleSearchChange}
            handleSearch={() => {}}
            height="32px"
          />
        </div>
      )}
      <div
        className="card-container"
        style={{
          borderTop: loading ? "none" : "1px solid #B7BED0",
          overflowY: loading ? "hidden" : isDesktop ? "auto" : "scroll",
        }}
      >
        {loading ? (
          <div style={{ marginTop: 35 }}>
            <Spinner size={60} />
          </div>
        ) : (
          <Box>{renderedCts()}</Box>
        )}
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "error" ? "Try again" : "Accept"}
        handleAction={modal.function}
        handleClose={
          modal.action === "success"
            ? modal.function
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};

export default CTSManager;
