import "./HomeCard.css";
import PersonIcon from "@mui/icons-material/Person";

interface WelcomeCardsProps {
  ctsOrMD: string;
  totalPatients: number;
  isDoctor?: boolean;
}

const HomeCard: React.FC<WelcomeCardsProps> = ({
  ctsOrMD,
  totalPatients,
  isDoctor = false,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h2
          className="welcome-cts-name"
          style={{
            color:
              ctsOrMD === "Deleted MDs" || ctsOrMD === "Deleted CTS"
                ? "#F7474B"
                : "",
          }}
        >
          {ctsOrMD}
          {isDoctor && ctsOrMD !== "Deleted MDs" ? ", MD" : ""}
        </h2>
        <div className="welcome-card-right-side">
          <PersonIcon sx={{ color: "#1D6198" }} />
          <h2 className="welcome-total-patients">{totalPatients}</h2>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
