import { createSlice } from "@reduxjs/toolkit";
import {
  getAllClinicalTrial,
  createClinicalTrial,
  updateClinicalTrial,
  deleteClinicalTrial,
  getClinicalTrialById,
} from "./clinicalTrialActions";
import { Statuses } from "../interfaces";
import { ClinicalTrial } from "../../../types/ClinicalTrial";

export interface CTState {
  clinicalTrials: ClinicalTrial[];
  loading: boolean;
  error: string | any;
  status: Statuses;
}

const initialState: CTState = {
  clinicalTrials: [],
  loading: false,
  error: null,
  status: "idle",
};

export const clinicalTrialSlice = createSlice({
  name: "clinicaltrial",
  initialState,
  reducers: {
    setLogout(state : CTState) {
      state.status = 'idle';
      state.error=null;
      state.clinicalTrials=[];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllClinicalTrial.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getAllClinicalTrial.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.error) state.clinicalTrials = action.payload;
      })
      .addCase(getAllClinicalTrial.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createClinicalTrial.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          const index = state.clinicalTrials.findIndex(
            (ct) => ct.id === action.payload.id
          );
          index === -1 &&
            state.clinicalTrials.push(
              action.payload as unknown as ClinicalTrial
            );
        }
      })
      .addCase(createClinicalTrial.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createClinicalTrial.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateClinicalTrial.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = "null";
      })
      .addCase(updateClinicalTrial.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateClinicalTrial.rejected, (state, action) => {
        state.status = "failed";
        state.error = null;
      })
      .addCase(deleteClinicalTrial.fulfilled, (state, action) => {
        state.status = "succeeded";

        if (!action.payload.error) {
          state.clinicalTrials = state.clinicalTrials.filter(
            (ct: ClinicalTrial) => ct.id !== action.meta.arg.idToDelete
          );
        }
      })
      .addCase(getClinicalTrialById.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.clinicalTrials.findIndex(
          (ct) => ct.id === action.payload.id
        );
        if (index !== -1) {
          state.clinicalTrials[index] =
            action.payload as unknown as ClinicalTrial;
        } else
          state.clinicalTrials.push(action.payload as unknown as ClinicalTrial);
      })
      .addCase(getClinicalTrialById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getClinicalTrialById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

type State = { ctState: CTState };
export const selectAllClinicalTrials = (state: State) =>
  state.ctState.clinicalTrials;
export const selectClinicalTrialStatus = (state: State) => state.ctState.status;
export const selectClinicalTrialsError = (state: State) => state.ctState.error;

export default clinicalTrialSlice.reducer;
