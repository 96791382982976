import "./PatientDetails.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { DocumentData } from "@google-cloud/firestore";
import {
  IconButton,
  MenuItem,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { MD } from "src/types/MD";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { fetchMDs } from "src/redux/slices/mds/mdsActions";
import {
  deletePatient,
  updatePatient,
} from "src/redux/slices/patients/patientActions";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import Spinner from "src/components/Spinner/Spinner";
import Modal from "src/components/Modal/Modal";
import SelectInput from "src/components/Inputs/SelectInput";
import FilledBtn from "src/components/Buttons/FilledBtn";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

const PatientDetails = () => {
  const navigate = useNavigate();
  const { state: patientData } = useLocation();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedMD, setSelectedMD] = useState<MD | null>(null);
  const dispatch = useAppDispatch();
  const auth = getAuth();
  const mds = useAppSelector((s) => s.MDs.MDs);
  const patientMD = mds.length
    ? mds.find((md: MD) => md.id === patientData?.md)
    : null;
  const [validatedMds, setValidatedMds] = useState(
    mds.length ? mds.filter((md: MD) => md.validate === true) : []
  );

  const loggedUser = useAppSelector((s) => s.userState.loggedUser);

  const [token, setToken] = useState("");
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("loggedUser") ?? "")?.role ??
      loggedUser?.role
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleSetEditing = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  // Init screen
  useEffect(() => {
    getToken().then((res) => setToken(res ?? ""));
  }, []);

  useEffect(() => {
    setLoading(true);

    if (token.length && role.length) {
      if (!patientMD) {
        if (!validatedMds.length) getMD();
        else setLoading(false);
      } else setLoading(false);
    }
  }, [token, role, patientMD]);

  const getMD = async () => {
    setLoading(true);
    try {
      const response = await dispatch(fetchMDs({ token, role }));
      if (!response.payload.error) {
        setValidatedMds(response.payload.filter((md: MD) => md.validate));
      } else {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsModalOpen(false);
          },
        });
        setIsModalOpen(true);
        setValidatedMds([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsModalOpen(false);
        },
      });
      setIsModalOpen(true);
    }
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  //MODAL
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    dispatch(deletePatient({ patientIdToDelete: patientData.id, token, role }))
      .then((response: DocumentData) => {
        if (!response.payload.error) {
          setIsModalOpen(false);
          navigate("/patients", { replace: false });
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
            function: () => {
              setIsModalOpen(false);
            },
          });

          setIsModalOpen(true);
        }
      })
      .catch(() => {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsModalOpen(false);
          },
        });

        setIsModalOpen(true);
      });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      delete patientData.ctName;

      const response = await dispatch(
        updatePatient({
          idToUpdate: patientData.id,
          patientUpdated: { ...patientData, md: selectedMD?.id },
          token,
          role,
        })
      );
      setSubmitLoading(false);
      if (!response.payload.error) {
        setModal({
          action: "success",
          title: "Success!",
          content: "The patient's MD was updated.",
          function: () => {
            setIsModalOpen(false);
            navigate("/patients");
          },
        });

        setIsModalOpen(true);
      } else {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsModalOpen(false);
          },
        });

        setIsModalOpen(true);
      }
    } catch {
      setSubmitLoading(false);
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsModalOpen(false);
        },
      });

      setIsModalOpen(true);
    }
  };

  return (
    <div
      className="pd-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      {submitLoading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100vh",
            boxSizing: "border-box",
            zIndex: 1001,
            width: "100%",
            background: "rgb(183, 190, 208,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : undefined}
      <div
        className="d-flex justify-space-between align-center w100"
        style={{
          paddingBottom: isDesktop ? "4px" : "",
          borderBottom: isDesktop ? "1px solid #B7BED0" : "",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowBackBtn onClick={() => navigate("/patients")} />
          <h2
            className="font-title pd-page-title"
            style={{ marginLeft: "8px" }}
          >
            Patient
          </h2>
        </div>
        <div>
          {isEditing ? (
            <FilledBtn
              disabled={selectedMD === null}
              width="77px"
              fontSize="14px"
              text={"Save"}
              onClick={handleSubmit}
            />
          ) : !patientData ? undefined : (
            <IconButton
              size={isDesktop ? "large" : "small"}
              style={{ color: "#F7474B", padding: isDesktop ? "4px" : "" }}
              onClick={() => {
                setModal({
                  action: "warning",
                  title: "Are you sure?",
                  content:
                    "You are about to delete the patient. This action cannot be undone.",
                  function: () => {
                    handleDelete();
                    setIsModalOpen(false);
                  },
                });

                setIsModalOpen(true);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          )}
        </div>
      </div>
      <div className="w100">
        {!patientData && !loading ? (
          <div className="not-result-message">
            <span className="material-icons-outlined">
              <SearchOffIcon
                sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
              />
            </span>
            <h3 className="no-result-text">Patient not found</h3>
          </div>
        ) : (
          <Zoom
            in={true}
            style={{ transitionDelay: "100ms" }}
            mountOnEnter
            unmountOnExit
          >
            <div className="w100">
              {loading || !patientData ? (
                <div style={{ marginTop: "120px" }}>
                  <Spinner size={60} />
                </div>
              ) : (
                <section
                  className="pd-patient-info"
                  style={{
                    padding: isDesktop ? "8px 24px" : "",
                  }}
                >
                  <h3 className="pd-patient-name">
                    {makeCapitalLetter(patientData.name)}{" "}
                    {makeCapitalLetter(patientData.lastName)}
                  </h3>
                  <div className="pd-line">
                    <span>preview</span>
                    <h4>CLINICAL TRIAL:</h4>
                    <div className="label">
                      <h3>{patientData.ctName}</h3>
                    </div>
                  </div>
                  <div className="pd-line">
                    <span>phone_iphone</span>
                    <h4>PHONE:</h4>
                    <h3 style={{ fontSize: "16px" }}>{patientData.phone}</h3>
                    <h4 style={{ marginLeft: "4px", fontSize: "16px" }}>
                      {" "}
                      ({patientData.phone_type}){" "}
                    </h4>
                  </div>
                  <div className="pd-line">
                    <span>mail_outline</span>
                    <h4>EMAIL:</h4>
                    <h3 style={{ fontSize: "16px" }}>{patientData.email}</h3>
                  </div>
                  <div className="pd-line" style={{ marginBottom: "6px" }}>
                    <span>medical_information</span>
                    <h4 style={{ marginRight: "4px" }}>MD:</h4>
                    {patientMD ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <VerifiedUserIcon
                          style={{
                            height: "16px",
                            width: "16px",
                            color: patientMD.validate ? "#58BD9F" : "#F2F2F2",
                          }}
                        />
                        <h3 style={{ margin: 0, fontSize: "16px" }}>
                          {patientMD.name}
                        </h3>
                      </div>
                    ) : (
                      <div className="w100">
                        {!isEditing ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <h3 className="h3inside">Not registered</h3>
                            <IconButton
                              style={{ padding: 0 }}
                              onClick={handleSetEditing}
                            >
                              <CreateOutlinedIcon
                                sx={{ color: "#1D6198", height: "16px" }}
                              />
                            </IconButton>
                          </div>
                        ) : (
                          <div
                            className="detail-input-container"
                            style={{ width: "60%" }}
                          >
                            <SelectInput
                              name="md"
                              value={selectedMD?.name || ""}
                              required={false}
                              changeFunction={(event) => {
                                setSelectedMD(
                                  validatedMds.find(
                                    (el: MD) => el.name === event.target.value
                                  )!
                                );
                              }}
                              placeholder="Treating/Referring MD"
                              items={validatedMds.map((md: MD, i: number) => {
                                return (
                                  <MenuItem value={md.name} key={i}>
                                    {md.name}
                                  </MenuItem>
                                );
                              })}
                              label={""}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </section>
              )}
              <Modal
                open={isModalOpen}
                action={modal.action}
                title={modal.title}
                content={modal.content}
                btnActionText={
                  modal.action === "error"
                    ? "Try again"
                    : modal.action === "success"
                    ? "Accept"
                    : "Delete"
                }
                handleClose={
                  modal.action === "success"
                    ? modal.function
                    : () => {
                        setIsModalOpen(false);
                      }
                }
                handleAction={modal.function}
              />
            </div>
          </Zoom>
        )}
      </div>
    </div>
  );
};

export default PatientDetails;
