import "./Home.css";
import { useEffect, useState } from "react";
import { DocumentData } from "@google-cloud/firestore";
import { getAuth } from "firebase/auth";
import { Box, Zoom, useMediaQuery, useTheme } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  fetchPatientsAmountByCts,
  fetchPatientsAmountByMd,
} from "src/redux/slices/patients/patientActions";
// @ts-ignore
import { ReactComponent as Logo } from "src/assets/favicon-sf.svg";
import FilterTab from "src/components/FilterTab/FilterTab";
import Spinner from "src/components/Spinner/Spinner";
import WelcomeCards from "src/components/HomeCard/HomeCard";
import Modal from "src/components/Modal/Modal";

const Home = () => {
  // Utils
  const dispatch = useAppDispatch();
  const auth = getAuth();

  // Data
  const patients = useAppSelector((s) => s.patients);
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);
  const patientsAmountByCTS = useAppSelector(
    (s) => s.patients.patientsAmountByCTS
  );
  const patientsAmountByMD = useAppSelector(
    (s) => s.patients.patientsAmountByMD
  );

  const patientsTotal = useAppSelector((s) => s.patients.patientsTotal);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [activeFilter, setActiveFilter] = useState("Clinical trial sites");
  const [loading, setLoading] = useState(true);
  const [disableFilters, setDisableFilters] = useState(true);
  const [token, setToken] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    action: "",
    title: "",
    content: "",
    function: () => {},
  });

  useEffect(() => {
    getToken().then((res) => setToken(res ?? ""));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (token.length) {
      getPatientsByCTS().finally(() =>
        getPatientsByMD().finally(() => {
          setLoading(false);
        })
      );
    }
  }, [token]);

  useEffect(() => {
    if (patients.status === "succeeded" || patients.status === "failed")
      setLoading(false);
  }, [patients]);

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  const getPatientsByCTS = async () => {
    try {
      const response: DocumentData = await dispatch(
        fetchPatientsAmountByCts({ token, role: loggedUser?.role })
      );

      if (!response.payload.error) {
        if (response.payload?.length) {
          setTimeout(() => {
            setDisableFilters(false);
          }, response.payload.length * 10);
        }
      } else {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  const getPatientsByMD = async () => {
    try {
      const response: DocumentData = await dispatch(
        fetchPatientsAmountByMd({ token, role: loggedUser?.role })
      );
      if (response.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      } else {
        if (response.payload?.length) {
          setTimeout(() => {
            setDisableFilters(false);
          }, response.payload.length * 10);
        }
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  const renderedSites = () => {
    if (patientsAmountByCTS.length > 0) {
      return patientsAmountByCTS.map((item, i) => (
        <Zoom
          in={true}
          style={{ transitionDelay: `${i * 50}ms` }}
          mountOnEnter
          unmountOnExit
          key={item.cts}
        >
          <div style={{ cursor: "pointer" }}>
            <WelcomeCards ctsOrMD={item.cts} totalPatients={item.patients} />
          </div>
        </Zoom>
      ));
    } else {
      return (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">There are no results</h3>
        </div>
      );
    }
  };

  const renderedMDs = () => {
    if (patientsAmountByMD.length > 0) {
      return patientsAmountByMD.map((item, i) => (
        <Zoom
          in={true}
          style={{ transitionDelay: `${i * 75}ms` }}
          mountOnEnter
          unmountOnExit
          key={item.md}
        >
          <div style={{ cursor: "pointer" }}>
            <WelcomeCards
              ctsOrMD={item.md}
              isDoctor={true}
              totalPatients={item.patients}
            />
          </div>
        </Zoom>
      ));
    } else {
      return (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">There are no results</h3>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        className="page-container"
        style={{
          paddingLeft: isDesktop ? "25%" : "",
          paddingRight: isDesktop ? "15%" : "",
          boxSizing: "border-box",
        }}
      >
        {loading ? (
          <div
            style={{
              marginTop: "25%",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div className="w100">
            <div
              style={{
                width: "100%",
                paddingTop: isDesktop ? "8px" : "",
                borderTop: isDesktop ? "1px solid #B7BED0" : "",
              }}
            >
              <section className="welcome-header">
                <span
                  className="welcome-logo-wrapper"
                  style={{
                    paddingTop: isDesktop ? "8px" : "",
                  }}
                >
                  <Logo />
                </span>
                <div>
                  <h2 className="welcome-header-title">
                    {loading ? <Spinner /> : patientsTotal}
                  </h2>
                  <h3 className="welcome-header-subtitle">Total patients</h3>
                </div>
              </section>
            </div>
            <div className="w100">
              {!loading ? (
                <FilterTab
                  width={100}
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  options={["Clinical trial sites", "MDs"]}
                  disabled={disableFilters}
                  blue={true}
                />
              ) : undefined}
            </div>
            <section className="card-container welcome-card-container">
              <Box>
                {loading ? (
                  <Spinner />
                ) : activeFilter === "Clinical trial sites" ? (
                  renderedSites()
                ) : (
                  renderedMDs()
                )}
              </Box>
            </section>
          </div>
        )}
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={modal.action === "error" ? "Try again" : "Accept"}
        handleAction={modal.function}
        handleClose={
          modal.action === "success"
            ? modal.function
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};

export default Home;
