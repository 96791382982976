import "../page-styles.css";
import "./Patients.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DocumentData } from "@google-cloud/firestore";
import { getAuth } from "firebase/auth";
import { Zoom, useMediaQuery, useTheme, Box } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Patient from "src/types/Patient";
import { ClinicalTrial } from "src/types/ClinicalTrial";
import { useAppSelector } from "src/redux/hooks";
import { fetchPatientsByCTS } from "src/redux/slices/patients/patientActions";
import { getAllClinicalTrial } from "src/redux/slices/clinical-trials/clinicalTrialActions";
import { getCTSByEmail } from "src/redux/slices/clinical-trial-sites/ctsActions";
import PatientCard from "src/components/Patients/Card/PatientCard";
import SearchInput from "src/components/Inputs/SearchInput";
import Spinner from "src/components/Spinner/Spinner";
import FilterTab from "src/components/FilterTab/FilterTab";
import Modal from "src/components/Modal/Modal";

const Patients = () => {
  // Utils
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const auth = getAuth();

  // Data
  const patients = useAppSelector((s) => s.patients.patientsByCTS);
  const clinicalTrials = useAppSelector((s) => s.clinicalTrials.clinicalTrials);
  const loggedCTS = useAppSelector((s) => s.clinicalTrialSites.loggedCTS);
  const [patientsWithData, setPatientsWithData] = useState<
    { patient: Patient; ctName: string }[]
  >([]);
  const [patientsDisplayed, setPatientsDisplayed] = useState<
    { patient: Patient; ctName: string | undefined }[]
  >([]);
  const [CTNames, setCTNames] = useState<string[]>([]);
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);

  // Func
  const [search, setSearch] = useState("");
  const [activeFilter, setActiveFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [disableFilters, setDisableFilters] = useState(true);
  const [token, setToken] = useState("");
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem("loggedUser") ?? "")?.role ??
      loggedUser?.role
  );

  //MODAL
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Init screen
  useEffect(() => {
    getToken()
      .then((res) => setToken(res ?? ""))
      .catch(() => {
        setLoading(false);
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsModalOpen(false);
          },
        });

        setIsModalOpen(true);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (token.length && role.length) {
      getPatients().then(() =>
        getClinicalTrials().finally(() => setLoading(false))
      );
    }
  }, [token]);

  // Initial setting of patients
  useEffect(() => {
    const arrayToSet = patients.map((p: Patient) => {
      const ct = clinicalTrials
        .filter((ct: ClinicalTrial) => {
          return p.clinicalTrial === ct.id;
        })
        .at(0);
      return {
        patient: p,
        ctName: ct?.name ? ct.name : "Deleted trial",
      };
    });
    arrayToSet.sort(function (
      a: {
        patient: Patient;
        ctName: string;
      },
      b: {
        patient: Patient;
        ctName: string;
      }
    ) {
      return a.patient.name.localeCompare(b.patient.name);
    });
    setPatientsWithData(arrayToSet);
    setPatientsDisplayed(arrayToSet);
  }, [patients, clinicalTrials]);

  // Set static names so it doesn't have to calculate every time
  useEffect(() => {
    const ongoingClinicalTrials: string[] = [];

    const notGoingClinicalTrials: string[] = [];

    clinicalTrials.map((ct: ClinicalTrial) =>
      ct.ongoing
        ? ongoingClinicalTrials.push(ct.name)
        : notGoingClinicalTrials.push(ct.name)
    );

    const ctNames = ongoingClinicalTrials
      .sort()
      .concat(notGoingClinicalTrials.sort());

    ctNames.unshift("All");

    setCTNames(ctNames);
  }, [clinicalTrials]);

  // Search and filter
  useEffect(() => {
    let newPatientsDisplayed: {
      patient: Patient;
      ctName: string | undefined;
    }[] = [];
    if (activeFilter !== "All") {
      newPatientsDisplayed = patientsWithData.filter((p) => {
        const ctName = p.ctName ? p.ctName.toLowerCase() : "Deleted trial";
        return activeFilter.toLowerCase().includes(ctName.toLowerCase());
      });
    } else newPatientsDisplayed = patientsWithData;

    const patientsToDisplay = newPatientsDisplayed.filter((p) => {
      const patientName = p.patient.name + " " + p.patient.lastName;
      return patientName.toLowerCase().includes(search.toLowerCase());
    });
    setPatientsDisplayed(patientsToDisplay);
  }, [search, activeFilter]);

  const getToken = async () => {
    const result = await auth.currentUser?.getIdToken();
    if (result?.length) return result;
  };

  const getPatients = async () => {
    try {
      if (loggedCTS) {
        const response: DocumentData = await dispatch(
          fetchPatientsByCTS({
            ctsId: loggedCTS.id,
            token,
            role,
          })
        );
        if (!response.payload.error) {
          if (response.payload?.length) {
            setTimeout(() => {
              setDisableFilters(false);
            }, response.payload.length * 150);
          }
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
            function: () => {
              setIsModalOpen(false);
            },
          });

          setIsModalOpen(true);
          setPatientsDisplayed([]);
        }
      } else {
        const loggedCTSResponse = await dispatch(
          getCTSByEmail({
            email: auth.currentUser
              ? auth.currentUser.email
              : loggedUser?.email,
            token,
            role,
          })
        );
        if (!loggedCTSResponse.payload.error) {
          const response: DocumentData = await dispatch(
            fetchPatientsByCTS({
              ctsId: loggedCTSResponse.payload.id,
              token,
              role,
            })
          );
          if (!response.payload.error) {
            if (response.payload?.length) {
              setTimeout(() => {
                setDisableFilters(false);
              }, response.payload.length * 150);
            }
          } else {
            setModal({
              action: "error",
              title: "Uh oh!",
              content: "An unexpected error occurred.",
              function: () => {
                setIsModalOpen(false);
              },
            });

            setIsModalOpen(true);
            setPatientsDisplayed([]);
          }
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected error occurred.",
            function: () => {
              setIsModalOpen(false);
            },
          });

          setIsModalOpen(true);
          setPatientsDisplayed([]);
        }
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsModalOpen(false);
        },
      });

      setIsModalOpen(true);
      setPatientsDisplayed([]);
    }
  };

  const getClinicalTrials = async () => {
    try {
      const response = await dispatch(getAllClinicalTrial({ token, role }));
      if (response.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            setIsModalOpen(false);
          },
        });

        setIsModalOpen(true);
        setCTNames([]);
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          setIsModalOpen(false);
        },
      });

      setIsModalOpen(true);
      setCTNames([]);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const renderPatients = () => {
    if (patientsDisplayed.length > 0) {
      return patientsDisplayed.map((user, i) => (
        <Zoom
          in={true}
          style={{ transitionDelay: `${i * 50}ms` }}
          mountOnEnter
          unmountOnExit
          key={user.patient.id}
        >
          <div style={{ cursor: "pointer" }}>
            <PatientCard patient={user.patient} clinicalTrial={user.ctName} />
          </div>
        </Zoom>
      ));
    } else {
      return (
        <div className="not-result-message">
          <span className="material-icons-outlined">
            <SearchOffIcon
              sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
            />
          </span>
          <h3 className="no-result-text">There are no results</h3>
        </div>
      );
    }
  };

  return (
    <div
      className="page-container"
      style={{
        paddingLeft: isDesktop ? "25%" : "",
        paddingRight: isDesktop ? "15%" : "",
        boxSizing: "border-box",
      }}
    >
      <div
        className="w100"
        style={{
          marginTop: "4px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          className="font-title"
          style={{
            lineHeight: "30px",
            fontSize: "24px",
            margin: 0,
            marginBottom: isDesktop ? "" : "12px",
          }}
        >
          Patient database
        </h2>
        {isDesktop && (
          <div style={{ width: "33%" }}>
            <SearchInput
              value={search}
              handleSearch={() => {}}
              onChange={handleSearchChange}
              height="32px"
            />
          </div>
        )}
      </div>
      {!isDesktop && (
        <div style={{ width: "100%" }}>
          <SearchInput
            value={search}
            handleSearch={() => {}}
            onChange={handleSearchChange}
            height="32px"
          />
        </div>
      )}
      <div className="w100">
        <div
          className="d-flex justify-space-between w100"
          style={{ marginBottom: 8 }}
        ></div>
        <div>
          {!loading ? (
            <FilterTab
              disabled={disableFilters}
              options={CTNames}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          ) : undefined}
        </div>
        <div className="card-container patient-list">
          {loading ? (
            <div style={{ marginTop: 35 }}>
              <Spinner size={60} />
            </div>
          ) : (
            <Box>{renderPatients()}</Box>
          )}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText="Try again"
        handleClose={
          modal.action === "success"
            ? modal.function
            : () => {
                setIsModalOpen(false);
              }
        }
        handleAction={modal.function}
      />
    </div>
  );
};

export default Patients;
