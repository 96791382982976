import "src/pages/page-styles.css";
import "./CTSDetails.css";
import "src/components/Inputs/CustomInput/CustomInput";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Zoom, useMediaQuery, useTheme } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useAppDispatch } from "src/redux/hooks";
import { deleteCTS } from "src/redux/slices/clinical-trial-sites/ctsActions";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import Modal from "src/components/Modal/Modal";

export const CTSDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDelete = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);

    try {
      const result = await dispatch(
        deleteCTS({
          idToDelete: state.ctsProps.id,
          token: state.token,
          role: state.role,
        })
      );

      if (result.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            event.preventDefault();
            event.stopPropagation();
            setIsOpen(false);
          },
        });

        setIsOpen(true);
      } else navigate("/cts-manager");
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          event.preventDefault();
          event.stopPropagation();
          setIsOpen(false);
        },
      });

      setIsOpen(true);
    }
  };

  const handleEdit = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    navigate(`/cts-manager/edit/${state.ctsProps.id}`, {
      replace: false,
      state: {
        ctsProps: state.ctsProps,
        type: "edit",
        token: state.token,
        role: state.role,
      },
    });
  };

  return (
    <>
      <div
        className="page-container"
        style={{
          paddingBottom: "7em",
          height: "74vh",
          paddingLeft: isDesktop ? "25%" : "",
          paddingRight: isDesktop ? "15%" : "",
          boxSizing: "border-box",
        }}
      >
        <div
          className="d-flex justify-space-between align-center w100"
          style={{
            paddingBottom: isDesktop ? "4px" : "",
            borderBottom: isDesktop ? "1px solid #B7BED0" : "",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ArrowBackBtn onClick={() => navigate("/cts-manager")} />
            <h2
              className="font-title"
              style={{ marginLeft: "8px", marginTop: 0, marginBottom: 0 }}
            >
              Clinical trial site
            </h2>
          </div>
          {!state ? undefined : (
            <div>
              <IconButton
                size={isDesktop ? "large" : "small"}
                style={{ color: "#F7474B", padding: isDesktop ? "4px" : "" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({
                    action: "warning",
                    title: "Are you sure?",
                    content:
                      "You are about to delete the clinical trial site. This action cannot be undone.",
                    function: () => handleDelete,
                  });
                  setIsOpen(true);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
              <IconButton
                size={isDesktop ? "large" : "small"}
                style={{ color: "#1D6198", padding: isDesktop ? "4px" : "" }}
                onClick={handleEdit}
              >
                <EditIcon />
              </IconButton>
            </div>
          )}
        </div>
        {!state ? (
          <div className="not-result-message">
            <span className="material-icons-outlined">
              <SearchOffIcon
                sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
              />
            </span>
            <h3 className="no-result-text">Clinical trial site not found</h3>
          </div>
        ) : (
          <Zoom
            in={true}
            style={{ transitionDelay: "100ms" }}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={"sites-details w100 d-flex flex-column"}
              style={{ alignItems: "start", marginTop: isDesktop ? "8px" : "" }}
            >
              <h3 className={"cts-name"}>{state.ctsProps?.name}</h3>
              <p className={"info"}>
                <span className={"label"}>Address:</span>{" "}
                {state.ctsProps?.address}
              </p>
              <p className={"info"}>
                <span className={"label"}>Apartment, suite, floor, etc.:</span>{" "}
                {state.ctsProps?.specifications}
              </p>
              <p className={"info"}>
                <span className={"label"}>City, State:</span>{" "}
                {state.ctsProps?.city}
              </p>
              <p className={"info"}>
                <span className={"label"}>Zip code:</span>{" "}
                {state.ctsProps?.zip_code}
              </p>
              <p className={"info"}>
                <span className={"label"}>Email:</span> {state.ctsProps?.email}
              </p>
              <p className={"info"}>
                <span className={"label"}>Phone:</span> {state.ctsProps?.phone}
              </p>
            </div>
          </Zoom>
        )}
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        handleClose={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        handleAction={
          modal.action === "error"
            ? (e) => {
                e.stopPropagation();
                setIsOpen(false);
              }
            : (e) => handleDelete(e)
        }
        btnActionText={modal.action === "warning" ? "Delete" : "Try again"}
      />
    </>
  );
};
