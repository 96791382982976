import "./MDCard.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { MD } from "src/types/MD";
import { useAppDispatch } from "src/redux/hooks";
import { deleteMD } from "src/redux/slices/mds/mdsActions";
import Modal from "src/components/Modal/Modal";
import OutlinedBtn from "src/components/Buttons/OutlinedBtn";

interface MDCardProps {
  MDProps: MD;
  token: string;
  role: string;
}

const MDCard: React.FC<MDCardProps> = ({ MDProps, token, role }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // MODAL
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState<{
    action: string;
    content: string;
    title: string;
    function: () => void;
  }>({
    action: "",
    content: "",
    title: "",
    function: () => {},
  });

  const handleShowModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    handleShowModal();

    try {
      setIsOpen(false);
      const response = await dispatch(
        deleteMD({ idToDelete: MDProps.id, token, role })
      );
      if (response.payload.error) {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected error occurred.",
          function: () => {
            event.stopPropagation();
            event.preventDefault();
            setIsOpen(false);
          },
        });
        setIsOpen(true);
      } else {
        setModal((prevState) => ({
          ...prevState,
          action: "",
          content: "",
          title: "",
        }));
      }
    } catch (error) {
      setModal({
        action: "error",
        title: "Uh oh!",
        content: "An unexpected error occurred.",
        function: () => {
          event.stopPropagation();
          event.preventDefault();
          setIsOpen(false);
        },
      });
      setIsOpen(true);
    }
  };

  const handleEdit = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    navigate(`/mds-manager/edit/${MDProps.id}`, {
      replace: false,
      state: { mdProps: MDProps, type: "edit", token, role },
    });
  };

  const handleClick = (event: React.SyntheticEvent) => {
    if (modal.action !== "warning") {
      event.stopPropagation();
      if (MDProps.validate)
        navigate(`/mds-manager/details/${MDProps.id}`, {
          replace: false,
          state: { mdProps: MDProps, token, role },
        });
    }
  };

  return (
    <div
      className={`card ${MDProps.validate ? "h36" : ""}`}
      onClick={handleClick}
      style={{
        display: "flex",
        alignContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <VerifiedUserIcon
            style={{
              height: "20px",
              width: "20px",
              color: MDProps.validate ? "#58BD9F" : "#F2F2F2",
            }}
          />
          <h3 className={"md-name"}>{MDProps.name + ", MD"}</h3>
        </div>
        {MDProps.validate ? (
          <div style={{ display: "flex", gap: "8px" }}>
            <IconButton
              sx={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                setModal({
                  action: "warning",
                  title: "Are you sure?",
                  content:
                    "You are about to delete the MD. This action cannot be undone.",
                  function: () => handleDelete,
                });
                setIsOpen(true);
              }}
            >
              <DeleteOutlineIcon sx={{ height: "20px", width: "20px" }} />
            </IconButton>
            <IconButton sx={{ padding: 0 }} onClick={handleEdit}>
              <EditIcon sx={{ height: "20px", width: "20px" }} />
            </IconButton>
          </div>
        ) : (
          <OutlinedBtn
            width="100px"
            text={"Validate"}
            clickFunction={(e: React.SyntheticEvent | undefined) =>
              handleEdit(e as React.SyntheticEvent)
            }
            disabled={false}
          />
        )}
      </div>
      <Modal
        open={isOpen}
        action={modal.action}
        title={modal.title}
        content={modal.content}
        btnActionText={
          modal.action === "warning"
            ? "Delete"
            : modal.action === "success"
            ? "Close"
            : "Try again"
        }
        handleAction={
          modal.action === "error"
            ? (e) => {
                e.stopPropagation();
                setIsOpen(false);
              }
            : modal.action === "warning"
            ? handleDelete
            : modal.function
        }
        handleClose={
          modal.action === "success"
            ? modal.function
            : (e) => {
                e.stopPropagation();
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};

export default MDCard;
