import { InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import FormHelperText from "@mui/material/FormHelperText";
import { makeCapitalLetter } from "src/utils/makeCapitalLetter";

interface Item {
  icon: string;
  value: string;
}

interface SelectProps {
  label: string;
  handleChange: (e: SelectChangeEvent) => void;
  items: Item[];
  selectedValue: any | null;
  width?: string;
  height?: string;
  required?: boolean;
  name: string;
  fontSize?: string;
  errorText?: string;
  hasError?: boolean;
}

const IconSelect: React.FC<SelectProps> = ({
  label,
  handleChange,
  items,
  width = "auto",
  height = "auto",
  selectedValue,
  required = true,
  name,
  fontSize = "16px",
  errorText = "",
  hasError = false,
}) => {
  const labelStyles = {
    fontFamily: "Karla, sans-serif",
    fontSize: fontSize,
    letterSpacing: "1px",
    color: "rgba(5, 10, 12, 1)",
    textAlign: "left",
    marginBottom: ".2em",
  };

  const inputStyles = {
    fontFamily: "DM Sans, sans-serif",
    background: "white",
    borderRadius: "8px",
    fontSize: "14px",
    color: "rgba(5, 10, 12, 1)",
    height,
    textAlign: "left",
    letterSpacing: "0.5px",
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "rgba(29, 97, 152, 1) !important",
      borderRadius: "8px",
    },
  };

  const handleSelect = (e: SelectChangeEvent) => {
    e.preventDefault();
    handleChange(e);
  };

  const renderedItems = items.map((item) => {
    return (
      <MenuItem
        key={item.value}
        value={item.value}
        sx={{
          fontFamily: "DM Sans, sans-serif",
        }}
      >
        <span
          className="material-symbols-outlined"
          style={{ color: "#B7BED0", fontSize: "16px" }}
        >
          {item.icon}
        </span>
        <span style={{ marginLeft: "10px", fontSize: "14px" }}>
          {makeCapitalLetter(item.value)}
        </span>
      </MenuItem>
    );
  });

  return (
    <div style={{ width }}>
      <InputLabel
        sx={{ ...labelStyles, marginTop: 0 }}
        required={required}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <Select
        name={name}
        value={selectedValue ? selectedValue : ""}
        onChange={handleSelect}
        IconComponent={() => (
          <KeyboardArrowDownRoundedIcon sx={{ marginRight: "8px" }} />
        )}
        sx={{
          ...inputStyles,
          width: "100%",
          height,
          "& .MuiSelect-select": {
            padding: 0,
            paddingLeft: "10px",
          },
          "& .MuiSelect-outlined": {
            paddingRight: 0,
          },
          "& fieldset": {
            borderWidth: "1px",
            borderColor: "rgba(29, 97, 152, 1) !important",
            borderRadius: "8px",
          },
        }}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <div
                style={{
                  fontFamily: "DM Sans, sans-serif",
                  color: "rgba(5, 10, 12, 0.4)",
                  position: "absolute",
                  height,
                  fontSize: "16px",
                }}
              >
                {label}
              </div>
            );
          }

          const item = renderedItems
            .filter((item) => item.key === selectedValue)
            .at(0);

          return (
            <MenuItem sx={{ paddingLeft: 0 }}>{item?.props.children}</MenuItem>
          );
        }}
      >
        {renderedItems}
      </Select>
      <FormHelperText
        sx={{
          fontFamily: "DM Sans, sans-serif",
          marginRight: 0,
          marginLeft: "2px",
          fontSize: "0.65rem",
          color: "#F7474B",
        }}
      >
        {hasError ? errorText : " "}
      </FormHelperText>
    </div>
  );
};

export default IconSelect;
