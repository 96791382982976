import "./MDForm.css";
import "src/components/form-styles.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import { IconButton, Slide, useMediaQuery, useTheme } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { MD } from "src/types/MD";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
  addNewMD,
  deleteMD,
  updateMD,
  fetchMdById,
} from "src/redux/slices/mds/mdsActions";
import TextInput from "src/components/Inputs/TextInput";
import Modal from "src/components/Modal/Modal";
import ArrowBackBtn from "src/components/Buttons/ArrowBackBtn";
import FilledBtn from "src/components/Buttons/FilledBtn";
import PhoneInput from "src/components/Inputs/PhoneInput";
import Spinner from "src/components/Spinner/Spinner";

const MDForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { state } = useLocation();

  // Modal
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    content: "",
    action: "",
    function: () => {},
  });
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const mds = useAppSelector((s) => s.MDs.MDs);

  const handleShowModal = () => {
    setIsOpen(!isOpen);
  };

  // Error handling
  const [errors, setErrors] = useState<{
    name: string | undefined;
    address: string | undefined;
    email: string | undefined;
    phone: string | undefined;
  }>({
    name: undefined,
    address: undefined,
    email: undefined,
    phone: undefined,
  });
  const [isTrimComplete, setIsTrimComplete] = useState<boolean>(false);
  const [firstSubmit, setFirstSubmit] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value ?? "" });
    setPhoneNumber(phoneNumber);
  };

  const [formData, setFormData] = useState<MD>({
    name: "",
    address: "",
    email: "",
    phone: "",
    office_name: "",
    poc: "",
  });

  const validateName = (name: string) => {
    if (name.length > 70)
      return "The name you entered is too long. Please enter a name with 70 characters or less.";
  };

  const validateAddress = (address: string) => {
    if (address.length > 99)
      return "The address must have 100 characters or less.";
  };

  const validateEmail = (email: string) => {
    if (!email) return;

    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailRegex.test(email)) {
      return "The email address you entered is not valid. Please ensure it follows the format example@domain.com.";
    }

    const currentEmail = state?.mdProps?.email?.toLowerCase();
    const newEmail = email.toLowerCase();

    if (
      newEmail !== currentEmail &&
      mds.some((item: MD) => newEmail === item.email?.toLowerCase())
    ) {
      return "The email is already used by another MD.";
    }
  };

  const validatePhone = (phone: any) => {
    if (phone)
      if (!isValidPhoneNumber(phone))
        return "The entered phone is not valid. Please enter a valid phone number.";
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    if (firstSubmit) {
      setErrors({ ...errors, name: validateName(event.target.value.trim()) });
    }
    setFormData({ ...formData, name: event.target.value });
  };

  const handleChangeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    if (firstSubmit) {
      setErrors({
        ...errors,
        address: validateAddress(event.target.value.trim()),
      });
    }
    setFormData({ ...formData, address: event.target.value });
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    if (firstSubmit) {
      setErrors({ ...errors, email: validateEmail(event.target.value.trim()) });
    }
    setFormData({ ...formData, email: event.target.value });
  };

  const handleChangePhone = (value: any) => {
    if (firstSubmit && value) {
      setErrors({ ...errors, phone: validatePhone(value) });
    }
    setPhoneNumber(value = value === undefined ? "" : value);
  };

  const handleDisabledButton = (formData: MD) => {
    return !formData.name.length;
  };

  const handleEdit = async () => {
    setLoading(true);
    const mdInDatabase = await dispatch(
      fetchMdById({
        idToGet: state.mdProps.id,
        token: state.token,
        role: state.role,
      })
    );

    if (state.mdProps.validate) {
      editMD();
    } else {
      if (!mdInDatabase.payload.validate) {
        editMD();
      } else {
        setModal({
          action: "error",
          title: "Uh oh!",
          content:
            "The MD was already validated while you were filling the form.",
          function: () => {
            setIsOpen(false);
            navigate("/mds-manager");
          },
        });
        setLoading(false);
        handleShowModal();
      }
    }
  };

  const editMD = () => {
    dispatch(
      updateMD({
        idToUpdate: state.mdProps.id,
        mdUpdated: { ...formData, phone: phoneNumber, validate: true },
        token: state.token,
        role: state.role,
      })
    )
      .then((response: any) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The MD was updated successfully.",
            function: () => {
              setIsOpen(false);
              navigate("/mds-manager");
            },
          });

          handleShowModal();
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected system error occurred.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
        }
      })
      .catch(() => {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected system error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      });
  };

  const handleCreate = () => {
    setLoading(true);

    dispatch(
      addNewMD({
        newMD: { ...formData, phone: phoneNumber, validate: true },
      })
    )
      .then((response: any) => {
        setLoading(false);
        if (!response.payload.error) {
          setModal({
            action: "success",
            title: "Success!",
            content: "The MD was created successfully.",
            function: () => {
              setIsOpen(false);
              navigate("/mds-manager");
            },
          });

          handleShowModal();
        } else {
          setModal({
            action: "error",
            title: "Uh oh!",
            content: "An unexpected system error occurred.",
            function: () => {
              setIsOpen(false);
            },
          });

          handleShowModal();
        }
      })
      .catch(() => {
        setModal({
          action: "error",
          title: "Uh oh!",
          content: "An unexpected system error occurred.",
          function: () => {
            setIsOpen(false);
          },
        });

        handleShowModal();
      });
  };

  const trimMD = () => {
    setFormData({
      name: formData.name.trim(),
      address: formData.address.trim(),
      poc: formData.poc?.trim(),
      office_name: formData.office_name?.trim(),
      email: formData.email.trim().toLowerCase(),
      phone: phoneNumber,
    });
    setIsTrimComplete(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFirstSubmit(true);
    trimMD();
  };

  useEffect(() => {
    if (isTrimComplete) {
      setIsTrimComplete(false);
      const error = {
        name: validateName(formData.name),
        address: validateAddress(formData.address),
        email: validateEmail(formData.email),
        phone: validatePhone(formData.phone),
      };

      if (
        !error.name &&
        !error.address &&
        (!error.email || formData.email === "") &&
        !error.phone
      ) {
        if (state && state.type === "edit") {
          handleEdit();
        } else {
          handleCreate();
        }
      } else {
        setErrors(error);

        setLoading(false);
      }
    }
  }, [isTrimComplete]);

  useEffect(() => {
    if (state && state?.type === "edit" && state?.mdProps) {
      setPhoneNumber(state.mdProps.phone);
      setFormData(state.mdProps);
    }
  }, []);

  return (
    <>
      <div
        className="page-container"
        style={{
          paddingLeft: isDesktop ? "25%" : "",
          paddingRight: isDesktop ? "15%" : "",
          boxSizing: "border-box",
        }}
      >
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              boxSizing: "border-box",
              zIndex: 1001,
              width: "100%",
              background: "rgb(183, 190, 208,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : undefined}
        {window.location.href.includes("/edit/") && !state ? (
          <div className="not-result-message">
            <span className="material-icons-outlined">
              <SearchOffIcon
                sx={{ fontSize: "120px", fontWeight: 100, color: "#B7BED0" }}
              />
            </span>
            <h3 className="no-result-text">MD not found</h3>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="w100">
            <div className="d-flex justify-space-between align-center w100">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ArrowBackBtn onClick={() => navigate("/mds-manager")} />
                <h2 className="font-title" style={{ marginLeft: "8px" }}>
                  {state && state.mdProps.validate ? "MD" : "Add MD"}
                </h2>
              </div>
              <div>
                {state && state.type === "edit" ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setModal({
                        action: "warning",
                        title: "Are you sure?",
                        content:
                          "You are about to delete the MD. This action cannot be undone.",
                        function: async () => {
                          setIsOpen(false);
                          setLoading(true);
                          const res = await dispatch(
                            deleteMD({
                              idToDelete: state.mdProps.id,
                              token: state.token,
                              role: state.role,
                            })
                          );

                          if (!res.payload.error) {
                            navigate("/mds-manager");
                          } else {
                            setLoading(false);
                            setModal({
                              action: "error",
                              title: "Uh oh!",
                              content: "An unexpected error occurred.",
                              function: () => {
                                setIsOpen(false);
                              },
                            });
                            setIsOpen(true);
                          }
                        },
                      });
                      setIsOpen(true);
                    }}
                    sx={{ margin: "4px", padding: "4px" }}
                  >
                    <DeleteOutlineIcon
                      sx={{
                        color: "rgba(247, 71, 75, 1)",
                        height: "20px",
                        fontWeight: 100,
                      }}
                    />
                  </IconButton>
                ) : null}
                <FilledBtn
                  width="77px"
                  text={"Save"}
                  disabled={handleDisabledButton(formData)}
                />
              </div>
            </div>
            <Slide in={true} direction="up" mountOnEnter unmountOnExit>
              <div
                className={"form-container w100 d-flex flex-column"}
                style={{
                  maxHeight: isDesktop ? "71vh" : "auto",
                }}
              >
                <TextInput
                  name={"name"}
                  label={"Name"}
                  placeholder={""}
                  value={formData.name || ""}
                  changeFunction={handleChangeName}
                  margin="0 0 .2em 0"
                />
                {errors.name ? (
                  <span className="cts-form-error">{errors.name}</span>
                ) : undefined}
                <TextInput
                  required={false}
                  name={"address"}
                  label={"Address"}
                  placeholder={""}
                  value={formData.address || ""}
                  changeFunction={handleChangeAddress}
                  margin=".7em 0 .2em 0"
                />
                {errors.address ? (
                  <span className="cts-form-error">{errors.address}</span>
                ) : undefined}
                <TextInput
                  required={false}
                  name={"email"}
                  label={"Email"}
                  placeholder={""}
                  value={formData.email || ""}
                  changeFunction={handleChangeEmail}
                  margin=".7em 0 .2em 0"
                />
                {errors.email ? (
                  <span className="cts-form-error">{errors.email}</span>
                ) : undefined}
                <PhoneInput
                  required={false}
                  name={"phone"}
                  label={"Phone"}
                  placeholder={""}
                  value={phoneNumber}
                  changeFunction={handleChangePhone}
                  margin=".7em 0 0 0"
                />
                {errors.phone ? (
                  <span className="cts-form-error">{errors.phone}</span>
                ) : undefined}
                <TextInput
                  required={false}
                  name={"office_name"}
                  label={"Name of office"}
                  placeholder={""}
                  value={formData.office_name || ""}
                  changeFunction={handleChange}
                  margin=".7em 0 .2em 0"
                />
                <TextInput
                  required={false}
                  name={"poc"}
                  label={"Point of contact"}
                  placeholder={""}
                  value={formData.poc || ""}
                  changeFunction={handleChange}
                  margin=".7em 0 .2em 0"
                />
              </div>
            </Slide>
          </form>
        )}

        <Modal
          open={isOpen}
          action={modal.action}
          title={modal.title}
          content={modal.content}
          btnActionText={modal.action === "error" ? "Try again" : "Accept"}
          handleAction={modal.function}
          handleClose={
            modal.action === "success"
              ? modal.function
              : () => {
                  setIsOpen(false);
                }
          }
        />
      </div>
    </>
  );
};

export default MDForm;
