import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ClinicalTrial } from "../../../types/ClinicalTrial";
const { REACT_APP_BACKEND_BASE_URL } = process.env;

export const getAllClinicalTrial: any = createAsyncThunk(
  "clinical-trial/getAllClinicalTrial",
  async ({ token, role }: { token: string; role: string }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial/getAllClinicalTrial`;
      const response = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const createClinicalTrial: any = createAsyncThunk(
  "clinical-trial/createClinicalTrial",
  async ({
    clinicalTrialToCreate,
    token,
    role,
  }: {
    clinicalTrialToCreate: ClinicalTrial;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial/createClinicalTrial`;
      const response = await axios.post<any>(url, clinicalTrialToCreate, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const updateClinicalTrial: any = createAsyncThunk(
  "clinical-trial/updateClinicalTrial",
  async ({
    idToUpdate,
    clinicalTrialUpdated,
    token,
    role,
  }: {
    idToUpdate: string;
    clinicalTrialUpdated: ClinicalTrial;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial/updateClinicalTrial/${idToUpdate}`;
      const response = await axios.put<any>(url, clinicalTrialUpdated, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const deleteClinicalTrial: any = createAsyncThunk(
  "clinical-trial/deleteClinicalTrial",
  async ({
    idToDelete,
    token,
    role,
  }: {
    idToDelete: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial/deleteClinicalTrial/${idToDelete}`;
      const response = await axios.delete<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);

export const getClinicalTrialById: any = createAsyncThunk(
  "clinical-trial/getClinicalTrialById",
  async ({
    idToGet,
    token,
    role,
  }: {
    idToGet: string;
    token: string;
    role: string;
  }) => {
    try {
      const url = `${REACT_APP_BACKEND_BASE_URL}/clinical-trial/getClinicalTrialById/${idToGet}`;
      const ctsResponse = await axios.get<any>(url, {
        headers: {
          Authorization: `${token}`,
          "Access-Control-Allow-Origin": "*",
          Role: role,
        },
      });
      return ctsResponse.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return { error: error.message };
      }

      return { error: "An unknown error occurred." };
    }
  }
);
