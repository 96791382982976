import { FormEvent, FormEventHandler } from "react";
import { Paper, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface InputSearchProps {
  value: string;
  placeholder?: string;
  handleSearch: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  height?: string;
}

const SearchInput: React.FC<InputSearchProps> = ({
  value,
  placeholder = "Search ...",
  onChange,
  handleSearch,
  width = "auto",
  height = "auto",
}) => {
  const handleSubmit: FormEventHandler<any> = (e: FormEvent) => {
    e.preventDefault();
    handleSearch();
  };

  return (
    <div>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width,
          height,
          border: "1px solid black",
          "& .MuiPaper-root": { height },
          boxShadow: 0,
          ".MuiButtonBase-root": { padding: 0, paddingLeft: "4px" },
          ".MuiInputBase-root > input": { paddingLeft: 0 },
        }}
        onSubmit={handleSubmit}
      >
        <IconButton
          onClick={handleSubmit}
          type="button"
          sx={{ p: "10px", color: "black", paddingRight: 0 }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          value={value}
          onChange={onChange}
          sx={{ ml: 1, flex: 1, "& ::placeholder": { opacity: 1 } }}
          placeholder={placeholder}
          inputProps={{ "aria-label": "search google maps" }}
        />
      </Paper>
    </div>
  );
};

export default SearchInput;
