import './Login.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { createTheme, useMediaQuery } from '@mui/material';
import { useAppSelector } from 'src/redux/hooks';
import { getCTSByEmail } from 'src/redux/slices/clinical-trial-sites/ctsActions';
import { getUserByEmail } from 'src/redux/slices/users/userActions';
import { userSlice } from 'src/redux/slices/users/userSlice';
// @ts-ignore
import desktopBG from 'src/assets/background-desktop.png';
// @ts-ignore
import mobileBG from 'src/assets/background-mobile.png';
// @ts-ignore
import LogoAmydis from 'src/assets/logo-amydis.png';
// @ts-ignore
import MicrosoftLogo from 'src/assets/microsoft-logo.svg';
import Modal from 'src/components/Modal/Modal';
import Spinner from 'src/components/Spinner/Spinner';
import User from 'src/types/User';
const { REACT_APP_AZURE_TENANT, REACT_APP_AZURE_CLIENT } = process.env;

export const Login = () => {
  const [handleOpenModal, setHandleOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loggedUser = useAppSelector((s) => s.userState.loggedUser);
  const [token, setToken] = useState('');

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1536,
      },
    },
  });

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClose = () => {
    setHandleOpenModal(false);
    setLoading(false);
  };

  const provider = new OAuthProvider('microsoft.com');

  provider.setCustomParameters({
    tenant: REACT_APP_AZURE_TENANT as string,
    client: REACT_APP_AZURE_CLIENT as string,
    authority: `https://login.microsoftonline.com/${REACT_APP_AZURE_TENANT}`,
    prompt: 'select_account',
  });

  const auth = getAuth();

  const setRouting = (user: User) => {
    switch (user.role) {
      case 'master':
        navigate('/users');
        break;
      case 'admin':
        navigate('/home');
        break;
      default:
        dispatch(
          getCTSByEmail({
            email: user.email,
            token,
            role: user.role,
          })
        ).then(() => navigate('/patients'));
        break;
    }
  };

  useEffect(() => {
    if (loggedUser && token.length) {
      setRouting(loggedUser);
    }
  }, [loggedUser, navigate]);

  const SignInButton = () => {
    const handleLogin = async () => {
      setLoading(true);
      try {
        const result = await signInWithPopup(auth, provider);

        if (!result.user) {
          setHandleOpenModal(true);
          return;
        }

        const token = await result.user.getIdToken();
        setToken(token);

        const userResponse = await dispatch(
          getUserByEmail({
            email: result.user.email,
            token: token,
          })
        );

        if (userResponse.payload.error) {
          setHandleOpenModal(true);
        } else {
          const userData = {
            ...userResponse.payload,
            name: result.user.displayName,
          };

          dispatch(userSlice.actions.setLoggedUser(userData));
          localStorage.setItem('loggedUser', JSON.stringify(userData));

          setRouting(userData);
        }
      } catch (error) {
        setHandleOpenModal(true);
      } finally {
        setLoading(false);
      }
    };

    return (
      <button className="login-button" onClick={handleLogin}>
        <img
          src={MicrosoftLogo}
          alt="microsoft logo"
          className="microsoft-logo"
        />
        <p className="login-button-text">Sign in with Microsoft</p>
      </button>
    );
  };

  return (
    <div>
      {loading ? (
        <div>
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: 0,
              height: '100vh',
              boxSizing: 'border-box',
              zIndex: 1001,
              width: '100%',
              background: '#00000075',
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '1002',
            }}
          >
            <Spinner color="#B7BED0" size={60} />
          </div>
        </div>
      ) : undefined}
      <div className="login-page">
        <div
          className="eye-gradient"
          style={{
            position: 'absolute',
            top: isDesktop ? '-10px' : '0',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            background:
              'radial-gradient(circle, #5A7F99 48.45%, #5A7F99 48.45%, #5A7F99 48.45%)',
            height: isDesktop ? '550px' : '360px',
            width: isDesktop ? '500px' : '360px',
            boxSizing: 'border-box',
            filter: 'blur(25px)',
            opacity: 1,
          }}
        ></div>
        <img
          src={isDesktop ? desktopBG : mobileBG}
          alt="Eye"
          className="eye-logo"
          style={{
            position: 'absolute',
            top: isDesktop ? '-10px' : '0',
            left: '50%',
            transform: isDesktop
              ? 'translateX(-50%) translateY(10px)'
              : 'translateX(-50%)',
            width: isDesktop ? '590px' : '',
            WebkitFilter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5))',
            filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5))',
          }}
        />

        <img src={LogoAmydis} alt="Amydis logo" className="amydis-logo" />

        <form
          onSubmit={(e) => e.preventDefault()}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '400px',
            width: '100%',
          }}
        >
          <SignInButton />
        </form>

        <Modal
          open={handleOpenModal}
          title="Uh oh"
          content="Seems like you don't have access to this app."
          handleAction={handleClose}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};
